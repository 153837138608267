import React from "react";

import { ListGroup, Dropdown, Form, Button, Modal, Alert, Row, Col, Container, Spinner } from "react-bootstrap";
import { connect } from "react-redux";

import {
  loadOrderList,
  loadSipStatus,
  cancelSipOrder,
  pauseSipOrder,
  createSwpOrder,
  twoFactorAuthentication,
  cancelSwpOrder,
  loadPaymentResponse
} from "../../actions/orders";
import Header1 from "../../layout/header1";
import Sidebar from "../../layout/sidebar";

import moment from 'moment';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

import Select from 'react-select';
import { Card, CardContent, CardHeader, Stack, Typography } from "@mui/material";
import { Fab, Action } from 'react-tiny-fab';
import 'react-tiny-fab/dist/styles.css';
import OTPInput, { ResendOTP } from "otp-input-react";

class SipList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      tab_orders: 0,
      tab_sip_order_child: 4,
      tab_xsip_order_child: 2,
      cardopen: false,
      list_sip_orders: [],
      list_xsip_orders: [],
      _list_xsip_orders: [],
      bank_information: {},
      status_filter_list: [],
      status_filter_selected: null,

      display_mode: `order_list`,
      notification_html: ``,

      is_otp_email_required: false,

      sip_cancellation_reason_list: [],
      cancellation_reason: {},

      swp_order: {
        display_text: '',
        swp_no_of_installments: '',
        swp_amount: '',
        swp_date: ``, // new Date(new Date().setDate(new Date().getDate() + 8)),
        swp_date_value: ``,
        swp_date_text: ``,
        swp_frequency: '',
        is_otp_enabled: false,
        two_factor_authentication: {
          mobile_otp: '',
          email_otp: '',
          twofactor_token_id: ''
        }
      },
      swp_minimum_date: new Date(new Date().setDate(new Date().getDate() + 8)),
      swp_maximum_date: new Date().setDate(new Date().getDate() + 40),
      swp_exclude_date_list: [],

      pause_order: {
        display_text: '',
        is_otp_enabled: false,
        two_factor_authentication: {
          mobile_otp: '',
          email_otp: '',
          twofactor_token_id: ''
        }
      },

      two_factor_authentication: {
        is_otp_enabled: false,
        mobile_otp: '',
        email_otp: '',
        twofactor_token_id: ''
      },

      is_verify_enabled: false,
      is_confirm_chk_enabled: false,
      is_verified_editable: false,
      esignkyc_information: {},
      esignkyc_attachment_list: {},
      is_esign_verified: false,
      is_esign_button_disabled: false,
      isloading: false,
      show_pause_popup: false,
      selected_pause_order: {},
      show_popup: false,
      popup_headerText: '',
      popup_messageText: '',

      show_swp_modal: false,
      show_swp_cancel_modal: false,
      show_stp_cancel_modal: false,
      show_view_modal: false,
      modal_orderinfo: {},
      modal_header_text: '',
      show_modal_filter: false,

      show_modal_action: false,
      modal_action_name: ``,
      modal_action_order: {},

      show_modal_pin_confirm_dialog: false,
      modal_pin_confirm_action: '',
      modal_pin_confirm_value: '',
      modal_pin_confirm_dialog_messagetext: 'Enter your 4 digit pin to complete your action',

      show_swpdate_popup: false,
      popup_swp_date_list: [],

      tabValue: "active",
      show_modal_otp_confirm_dialog: false,

    };
  }

  componentDidMount() {

    window.addEventListener("scroll", () => {

      if (document.getElementById("navbar-orderlist-container") &&
        document.getElementById("navbar") &&
        document.getElementById("navbar").classList) {
        const topBorder = document
          .getElementById("navbar-orderlist-container")
          .getBoundingClientRect().top;

        topBorder >= 0
          ? document.getElementById("navbar").classList.remove("fixed-orderlist")
          : document.getElementById("navbar").classList.add("fixed-orderlist");
      }

    });

    this.onLoadSipList(0);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', null);
  }

  handleConfirmCheck = (event) => {
    this.setState({
      is_confirm_chk_checked: !this.state.is_confirm_chk_checked,
    });
  };

  onLoadSipList = (order_type) => {

    this.setState({ isloading: true })

    order_type = order_type === 0 ? "xsip" : "sip";
    this.props
      .loadOrderList({
        order_type: order_type,
      })
      .then((response) => {

        if (this.props.order_result) {

          if (this.props.order_result["code"] !== "000") {
            this.setState({
              display_mode: ``,
              notification_html: ``,
              ["list_" + order_type + "_orders"]: [], ['_list_' + order_type + '_orders']: [],
              isloading: false
            });
            if (this.props.order_result["code"] !== "007")
              alert(this.props.order_result["messageText"]);
          }
          else {

            if (this.props.order_result["result"]) {
              const _filter_orders = (this.props.order_result["result"] || []).filter(el => {
                return el['mf_order_tab_category'] === this.state.tabValue;
              });

              this.setState({
                sip_cancellation_reason_list: this.props.order_result['sip_cancellation_reason_list'],
                bank_information: this.props.order_result["bank_information"],
                ["list_" + order_type + "_orders"]: this.props.order_result["result"],
                ["_list_" + order_type + "_orders"]: _filter_orders,
                status_filter_list: this.props.order_result['status_filter_list'],
                display_mode: 'order_list',
                notification_html: ``,
                isloading: false
              });
            }
            else this.setState({
              ["list_" + order_type + "_orders"]: [],
              isloading: false
            });

          }

        }
        else {
          this.setState({
            ["list_" + order_type + "_orders"]: [],
            isloading: false
          });
        }

      })
      .catch((err) => {
        alert(err);
      });
  };

  handleChangeStatus = (selectedOption) => {

    this.setState(
      {
        status_filter_selected: selectedOption
      },
      () => {

        let _filtered_list_xsip_orders = [];

        selectedOption.forEach(item => {
          _filtered_list_xsip_orders = _filtered_list_xsip_orders.concat(
            this.state.list_xsip_orders.filter(el => {
              return el[item['value']] === true;
            })
          );
        });

        this.setState({
          _list_xsip_orders: selectedOption.length > 0 ?
            _filtered_list_xsip_orders : this.state.list_xsip_orders
        });

      });

  };

  handleTabChange = (event, newValue) => {

    let _filter_orders = (this.state.list_xsip_orders || []).filter(el => {
      return el['mf_order_tab_category'] === newValue;
    })
    this.setState({
      tabValue: newValue,
      _list_xsip_orders: _filter_orders
    });

  };

  gotoCheckOutPage(request_mode, selected_order) {

    if (selected_order["mf_order_type"].toLowerCase() === "xsip") {
      this.props.history.push({
        pathname: "/sipEditCheckout/",
        state: {
          request_mode: request_mode,
          selected_order: Object.assign(selected_order, {
            bank_information: this.state.bank_information,
            request_mode: request_mode,
          }),
        },
      });
    } else {

      let _orderList = (selected_order["mf_order_action_list"] || []);

      if (_orderList.length > 0) {
        selected_order["order_information"] =
          selected_order['mf_order_action_list'][_orderList.length - 1];
      }

      selected_order["bank_information"] = this.state.bank_information;
      selected_order["request_mode"] = request_mode;

      this.props.history.push({
        pathname: "/sipEditCheckout/",
        state: { selected_order: selected_order },
      });
    }
  }

  showChildInformation(order_type, order, request_type) {

    if (request_type === "view") {

      this.setState({ isloading: true });

      this.props
        .loadSipStatus({
          request_mode: "order_payment_status",
          order_type: order_type,
          order_number: order["mf_order_number"] || "",
          order_id: order["_id"],
          selected_order: order,
          check_mandate_status: true,
          is_mobile_version: true
        })
        .then((response) => {

          this.setState({ isloading: false });

          if (this.props.order_result["code"] !== "000")
            alert(this.props.order_result["messageText"]);
          else {

            let _result = this.props.order_result["result"];
            if (_result) {

              let _list = this.state['list_' + order_type + '_orders'];
              let _new_list = [];

              _list.forEach(el => {

                if (el['mf_order_registration_no'] === _result['mf_order_registration_no']) {
                  _result['show_child'] = true;
                  _new_list.push(_result);
                }
                else
                  _new_list.push(el);
              });

              let _filtered_list_xsip_orders = (this.state.status_filter_selected || []).length > 0 ?
                [] : _new_list;


              (this.state.status_filter_selected || []).forEach(item => {
                _filtered_list_xsip_orders = _filtered_list_xsip_orders.concat(
                  _new_list.filter(el => {
                    return el[item['value']] === true;
                  })
                );
              });

              this.setState({
                modal_orderinfo: _result,
                show_view_modal: true,
                ["list_" + order_type + "_orders"]: _new_list,
                ["_list_" + order_type + "_orders"]: _filtered_list_xsip_orders,
                isloading: false
              });

            }
          }
        })
        .catch((err) => {
          alert(err);
        });
    } else {

      let _list = this.state["list_" + order_type + "_orders"];

      _list.forEach((el) => {
        if (
          order["mf_order_registration_no"] === el["mf_order_registration_no"]
        )
          el["show_child"] = false; // !el['show_child'];
      });

      this.filterListBasedOnStatus(_list, order_type);

    }
  }

  showSwpInformation(order_type, order, request_type) {

    let _result = this.state["list_" + order_type + "_orders"]; //this.props.order_result["result"];

    if (_result) {

      let _list = _result;

      _list.forEach((el) => {

        if (order["order_id"] === el["order_id"]) {

          this.setState({
            [request_type]: true,
            swp_order: {
              ...this.state.swp_order,
              is_otp_enabled: false,
              two_factor_authentication: {
                mobile_otp: '',
                email_otp: '',
                twofactor_token_id: ''
              }
            },
            modal_orderinfo: el
          });

        }
      });

      this.filterListBasedOnStatus(_list, order_type);

    }
  }

  showStpInformation(order_type, order, request_type) {

    let _result = this.state["list_" + order_type + "_orders"]; //this.props.order_result["result"];

    if (_result) {

      let _list = _result; //this.state["list_" + order_type + "_orders"];
      _list.forEach((el) => {

        if (order["order_id"] === el["order_id"]) {

          this.setState({
            [request_type]: true,
            // show_swp_modal: true,
            stp_order: {
              ...this.state.stp_order,
              is_otp_enabled: false,
              two_factor_authentication: {
                mobile_otp: '',
                email_otp: '',
                twofactor_token_id: ''
              }
            },
            modal_orderinfo: el
          });

        }
        else
          el['show_swp'] = false;
      });

      this.filterListBasedOnStatus(_list, order_type);

    }
  }

  resetFilter = (order_type) => {

    let _new_list = this.state['list_' + order_type + '_orders'] || [];

    let _state_parameters = {
      show_modal_filter: false,
      status_filter_selected: [],
      ['list_' + order_type + '_orders']: _new_list,
      ['_list_' + order_type + '_orders']: _new_list
    }

    this.setState(_state_parameters);

  }

  closePopup = () => {
    this.setState({
      show_pause_popup: false
    })
  }

  onCancelSip = (order_type, order) => {

    this.setState({ isloading: true });

    this.props
      .cancelSipOrder({
        order_type: order_type,
        client_info: order,
      })
      .then((response) => {

        alert(this.props.order_result["messageText"]);

        if (this.props.order_result["code"] !== "000") {
          this.setState({ isloading: false });
        }
        else {

          if (this.props.order_result["result"]) {

            this.setState({
              isloading: false,
              show_modal_action: false,
              show_modal_pin_confirm_dialog: false,
              display_mode: 'scheme_action_completed',
              notification_html: this.props.order_result["result"]['notification_html'] || ``,
              show_modal_pin_confirm_dialog: true,
              modal_pin_confirm_action: '',
              modal_pin_confirm_value: '',
              two_factor_authentication: {
                is_otp_enabled: false,
                mobile_otp: '',
                email_otp: '',
                twofactor_token_id: ''
              }
            });

            this.filterListBasedOnStatus(this.props.order_result, order_type, 'bank_information');

          }
          else {

            this.setState({
              ["list_" + order_type + "_orders"]: [],
              ["_list_" + order_type + "_orders"]: []
            });

          }

        }

      })
      .catch((err) => {
        alert(err);
      });

  };

  onPauseOrder = (order_type, order, mode) => {

    if (mode === 'initiate') {

      let _selected_pause_order = order;
      _selected_pause_order['installment_list'] = [];
      _selected_pause_order['no_of_installments'] = '';
      let _list = [];

      if (order['mf_order_is_pause_allowed']) {

        for (let iRec = parseInt(order['mf_order_pause_minimum_installments']);
          iRec <= parseInt(order['mf_order_pause_maximum_installments']); iRec++) {
          _list.push({ value: iRec, label: iRec });
        }
        _selected_pause_order['installment_list'] = _list;
      }

      this.setState({
        show_pause_popup: true, selected_pause_order: _selected_pause_order
      });

    }
    else {

      this.setState({ isloading: true });

      this.props
        .pauseSipOrder({
          order_type: order_type,
          client_info: Object.assign(
            { pause_order: this.state.pause_order }, order
          ),
        })
        .then((response) => {

          if (this.props.order_result["code"] !== "000") {
            alert(this.props.order_result["messageText"]);
            this.setState({ isloading: false });
          }
          else {

            if (this.props.order_result["result"]) {

              this.setState({
                isloading: false,
                show_modal_action: false,
                modal_action_name: ``,
                show_pause_popup: false,
                modal_pin_confirm_action: '',
                modal_pin_confirm_value: '',
                pause_order: {
                  display_text: '',
                  is_otp_enabled: false,
                  two_factor_authentication: {
                    mobile_otp: '',
                    email_otp: '',
                    twofactor_token_id: ''
                  }
                },
                modal_orderinfo: {},
                isloading: false,
                display_mode: 'scheme_action_completed',
                notification_html: this.props.order_result["result"]['notification_html'] || ``,
              });
              // this.filterListBasedOnStatus(this.props.order_result, order_type, 'bank_information');

            }
            else
              this.setState({
                isloading: false,
                ["list_" + order_type + "_orders"]: [],
                ["_list_" + order_type + "_orders"]: []
              });

            this.props.history.push({
              pathname: "/fyInformation/",
              state: { display_html: this.props.order_result['display_html'] || '' },
            });

          }

        })
        .catch((err) => {
          alert(err);
        });

    }

  };

  handleChangeSelect = (selectedOption, order, input, category) => {

    if (input === 'swp_frequency') {

      if (category === 'swp') {

        this.setState({
          swp_order: {
            ...this.state.swp_order,
            swp_frequency: selectedOption.value
          }
        });

      }

    }
    else if (input === 'swp_no_of_installments') {

      if (category === 'swp')
        this.setState({
          swp_order: {
            ...this.state.swp_order,
            swp_no_of_installments: selectedOption.value
          }
        });

    }

  }

  onCreateSwpOrder = (order_type, order) => {

    this.setState({ isloading: true });

    this.props
      .createSwpOrder({
        order_type: order_type,
        client_info: Object.assign(
          { swp_order: this.state.swp_order }, order
        ),
      })
      .then((response) => {

        if (this.props.order_result["code"] !== "000") {
          alert(this.props.order_result["messageText"]);
          this.setState({
            isloading: false
          });
        }
        else {

          let _result = this.props.order_result["result"];
          if (_result) {

            /*
            let _list = this.state['list_' + order_type + '_orders'];
            let _new_list = [];

            _list.forEach(el => {

              if (el['tab_xsip_order_child'] === _result['tab_xsip_order_child']) {
                _result['show_swp'] = false;
                _new_list.push(_result);
              }
              else
                _new_list.push(el);

            });
            */

            this.setState({
              // ["list_" + order_type + "_orders"]: _new_list,
              show_modal_action: false,
              modal_action_name: ``,
              show_swp_modal: false,
              swp_order: {
                ...this.state.swp_order,
                display_text: '',
                swp_no_of_installments: '',
                swp_amount: '',
                swp_date: ``, // new Date(new Date().setDate(new Date().getDate() + 8)),
                swp_date_value: ``,
                swp_date_text: ``,
                swp_frequency: '',
                is_otp_enabled: false,
                two_factor_authentication: {
                  mobile_otp: '',
                  email_otp: '',
                  twofactor_token_id: ''
                }
              },
              modal_orderinfo: {},
              isloading: false,
              display_mode: 'scheme_action_completed',
              notification_html: _result['notification_html'] || ``,
            });

          }
          // else 
          //   this.setState({ ["list_" + order_type + "_orders"]: [] });
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  onCancelSwpOrder = (order_type, order) => {

    this.setState({ isloading: true });

    this.props
      .cancelSwpOrder({
        order_type: order_type,
        client_info: Object.assign(
          { swp_order: this.state.swp_order }, order
        ),
      })
      .then((response) => {

        if (this.props.order_result["code"] !== "000") {
          alert(this.props.order_result["messageText"]);
          this.setState({
            isloading: false
          });
        }
        else {

          let _result = this.props.order_result["result"];
          if (_result) {

            this.setState({
              show_modal_action: false,
              modal_action_name: ``,
              show_swp_modal: false,
              swp_order: {
                ...this.state.swp_order,
                display_text: '',
                swp_no_of_installments: '',
                swp_amount: '',
                swp_date: ``,
                swp_date_value: ``,
                swp_date_text: ``,
                swp_frequency: '',
                is_otp_enabled: false,
                two_factor_authentication: {
                  mobile_otp: '',
                  email_otp: '',
                  twofactor_token_id: ''
                }
              },
              modal_orderinfo: {},
              isloading: false,
              display_mode: 'scheme_action_completed',
              notification_html: _result['notification_html'] || ``,
            });

          }

        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  onCancelStpOrder = (order_type, order) => {

    this.setState({ isloading: true });

    this.props
      .cancelStpOrder({
        order_type: order_type,
        client_info: Object.assign(
          { stp_order: this.state.stp_order }, order
        ),
      })
      .then((response) => {

        const _response = this.props.order_result;

        if (_response["code"] !== "000") {
          alert(_response["messageText"]);
          this.setState({ isloading: false });
        }
        else {

          let _result = _response['result'];
          if (_result) {

            let _list = this.state['list_' + order_type + '_orders'];
            let _new_list = [];

            _list.forEach(el => {

              _new_list.push(el);
              if (el['tab_xsip_order_child'] === _result['tab_xsip_order_child']) {
                _result['show_swp'] = false;
                _new_list.push(_result);
              }
            });

            this.setState({
              isloading: false,
              display_mode: 'scheme_action_completed',
              notification_html: _response['notification_html'] || ``,
            });

            this.filterListBasedOnStatus(_new_list, order_type);

          }

        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  filterListBasedOnStatus = (order_result, order_type, attribute_name) => {

    let _new_list = (order_result || []);

    let _filtered_list_xsip_orders = (this.state.status_filter_selected || []).length > 0 ?
      [] : _new_list;

    (this.state.status_filter_selected || []).forEach(item => {
      _filtered_list_xsip_orders = _filtered_list_xsip_orders.concat(
        _new_list.filter(el => {
          return el[item['value']] === true;
        })
      );
    });

    let _state_parameters = {
      ['list_' + order_type + '_orders']: _new_list,
      ['_list_' + order_type + '_orders']: _filtered_list_xsip_orders
    }

    if (attribute_name)
      _state_parameters[[attribute_name]] = order_result[attribute_name];

    this.setState(_state_parameters)

  }

  showTransactionHistory(item) {

    let _html = `<div>we will show you soon</div>`;

    this.setState({
      show_popup: true,
      popup_headerText: 'Transaction History',
      popup_messageText: item['modal_transaction_history_html'] || _html,
    })

  }

  closePopup = () => {
    this.setState({
      show_popup: false,
      popup_headerText: '',
      popup_messageText: ''
    });
  };

  createDefaultMarkup(text) {
    return {
      __html: text,
    };
  }

  htmlDefaultMarkup() {
    return {
      __html: this.state.notification_html,
    };
  }

  routeChange = (type, item, mode) => {

    /*if((window.matchMedia && window.matchMedia("(max-width: 480px)").matches) ){
        this.props.history.push({ 
          pathname: "/chartsmobile", 
          state: { param_data: {
              scheme_isin: item['scheme_isin'],
              scheme_code: item['scheme_code'], 
              scheme_name: item['scheme_name'] 
            }
          }
        });
    }
    else */

    this.props.history.push({
      pathname: '/consolidatedStatement/',
      state: {
        param_order: {
          order_registration_no: item['mf_order_registration_no'],
          order_number: item['mf_order_number'],
          order_type: item['mf_order_type'].toLowerCase(),
          order_id: item['_id'].toString()
        }
      }
    });

    /*
    this.props.history.push({ 
      pathname: "/consolidatedStatement/"
      + item['mf_order_registration_no'] + '/' + item['mf_order_number'] + '/'
      + item['mf_order_type'].toLowerCase()
      + '/' + localStorage.getItem('token')
    });
    */

  };

  sendOTP = (action_name) => {

    this.setState({ isloading: true });
    let request_action = ``;
    let attribute_name = ``;

    switch (action_name || this.state.modal_action_name) {

      case 'start_swp':
        request_action = 'swp registration';
        attribute_name = 'swp_order';
        break;
      case 'cancel_swp':
        request_action = 'swp cancellation';
        attribute_name = 'swp_order';
        break;
      case 'enable_pause_order':
        request_action = 'enable pause order';
        attribute_name = 'pause_order';
        break;
      case 'cancel_sip':
        request_action = 'sip order cancellation';
        attribute_name = 'two_factor_authentication';
        break;
      case 'disable_pause_order':
        request_action = 'disable pause order';
        attribute_name = 'pause_order';
        break;
      default:
        break;

    }

    this.props
      .twoFactorAuthentication({
        client_info: {
          request_action: request_action,
          is_otp_email_required: this.state.is_otp_email_required
        },
      })
      .then((response) => {

        if (this.props.order_result["code"] !== "000") {
          this.setState({ isloading: false });
          alert(this.props.order_result["messageText"]);
        }
        else {

          let _result = this.props.order_result; 

          if (_result && _result['twofactor_id']) {

            let _new_state = {
              isloading: false,
              modal_pin_confirm_action: action_name,
              [action_name]: {
                ...this.state[action_name],
                is_otp_enabled: true,
                two_factor_authentication: {
                  mobile_otp: '',
                  email_otp: '',
                  twofactor_token_id: _result['twofactor_id']
                }
              },
              two_factor_authentication: {
                is_otp_enabled: true,
                mobile_otp: '',
                email_otp: '',
                twofactor_token_id: _result['twofactor_id']
              }
            }
            if(attribute_name === 'two_factor_authentication')
              _new_state['show_modal_pin_confirm_dialog'] = true;

            this.setState(_new_state);
          }

        }
      })
      .catch((err) => {
        alert(err);
      });

  }

  refreshOrder = (item, is_redirect) => {

    this.setState({ isloading: true });

    this.props
      .loadPaymentResponse({
        request_mode: "order_payment_status",
        order_type: "xsip",
        order_number: item["mf_order_number"] || "",
        order_id: item["_id"],
        selected_order: item,
        is_mobile_version: true
      })
      .then((response) => {

        if (this.props.order_result) {

          if (this.props.order_result["code"] !== "000") {
            this.setState({
              isloading: false,
              show_popup: false,
              popup_messageText: this.props.order_result["messageText"]
            });
          }
          else {

            let _result = this.props.order_result["result"];

            if (_result) {

              let _list = this.state.list_xsip_orders;
              let _new_list = [];

              _list.forEach(el => {

                if (el['mf_order_registration_no'] === _result['mf_order_registration_no']) {

                  if (!(_result['ignore_order'] || false)) {
                    _result['show_child'] = true;
                    _new_list.push(_result);
                  }
                }
                else
                  _new_list.push(el);

              });

              let _filtered_list_xsip_orders = (this.state.status_filter_selected || []).length > 0 ?
                [] : _new_list;

              (this.state.status_filter_selected || []).forEach(item => {
                _filtered_list_xsip_orders = _filtered_list_xsip_orders.concat(
                  _new_list.filter(el => {
                    return el[item['value']] === true;
                  })
                );
              });

              this.setState({
                isloading: false,
                modal_orderinfo: !(_result['ignore_order'] || false) ? _result : {},
                show_view_modal: !(_result['ignore_order'] || false),
                list_xsip_orders: _new_list,
                _list_xsip_orders: _filtered_list_xsip_orders
              });

              if (_result['ignore_order']) {
                this.setState({
                  isloading: false,
                  show_popup: false,
                  popup_messageText: this.props.order_result["messageText"]
                });
              }


            }

          }

        }

      })
      .catch((err) => {
        alert(err);
      });


  }

  handle2FactorChange = (input, attribute, event) => e => {

    if (e)
      this.setState({
        [event]: {
          ...this.state[event],
          [attribute]: {
            ...this.state[event][attribute],
            [input]: e
          }
        }
      });
    else
      this.setState({ [input]: e });

  }

  handleCancelSipReason = (item) => {

    // this.setState({ isloading: true });
    // const _list = this.state.list_pending_mandate;
    // _list.forEach((el) => {
    //   if (el["mandate_id"] === item.value) item["is_selected"] = !item.value;
    //   else item["is_selected"] = false;
    // });

    console.log(item);

    this.setState({
      cancellation_reason: item,
      // list_pending_mandate: _list
    });
  };

  render() {

    const renderButton = buttonProps => {
      return (
        <div class="row">
          <div class="col">
            <button onClick={this.sendOTP} {...buttonProps}
              class={buttonProps.remainingTime !== 0 ? `btn btn-outline-secondary` : `btn btn-outline-success`}>
              {`Resend OTP`}
            </button>
          </div>
          <div class="col-6" >
            {buttonProps.remainingTime !== 0
              ? <span>
                You can resend otp in
                <span style={{ fontWeight: 'bold', color: 'green' }}>{' '}{buttonProps.remainingTime}</span> secs.
              </span>
              : ``}
          </div>
        </div>
      );
    };
    const renderTime = () => React.Fragment;

    return (
      <>
        <Header1 />
        <Sidebar />


        <div className="content-body" >
          <div className="container" style={{ marginBottom: 100 }}>

            <div className="row">

              <div id="navbar-orderlist-container">
                <div id="navbar" >
                  <div className="card" style={{ backgroundColor: '#f8f8fb' }}>
                    <div className="card-header">
                      <div class="row">
                        <div class="col">
                          {
                            this.state.display_mode === 'scheme_action_completed' ?
                              <h5></h5>
                              :
                              <h5>Sip Order list</h5>
                          }
                        </div>
                      </div>
                    </div>
                    <div class="row" style={{ marginTop: '-10px' }}>
                      <hr />
                    </div>
                  </div>
                </div>
              </div>

              <div style={{ marginTop: 60, marginBottom: 30 }}>

                {this.state.isloading && (
                  <Spinner
                    animation="border"
                    variant="dark"
                    style={{
                      position: "fixed",
                      top: "50%",
                      left: "50%",
                      zIndex: 899,
                    }}
                  />
                )}

                {this.state.isloading && //this.state.list_xsip_orders.length === 0 && (
                  <Alert variant="secondary text-center">
                    <Alert.Heading>
                      {/* {this.state.isloading
                        ? "We are fetching your orders"
                        : "No orders found"} */}
                      We are fetching your orders
                    </Alert.Heading>
                  </Alert>
                }

                {this.state.display_mode === 'scheme_action_completed' &&
                  <Alert variant="text-center">
                    <Alert.Heading>
                      <div className="row">
                        <div dangerouslySetInnerHTML={this.htmlDefaultMarkup()} />
                      </div>
                    </Alert.Heading>
                  </Alert>
                }
                {
                  this.state.display_mode === 'scheme_action_completed' &&
                  <div class="row" style={{ textAlign: 'center' }}>
                    <button type="button"
                      className="btn-sm btn-primary"
                      style={{ marginTop: '-5px', color: 'white', textAlign: 'center' }}
                      onClick={() => {
                        this.onLoadSipList(0);
                      }}
                    >
                      Ok, Go to Sip List
                    </button>
                  </div>
                }

                {
                  !this.state.isloading &&
                  this.state.display_mode !== 'scheme_action_completed' &&
                  <Box sx={{ width: '100%' }}>
                    <Tabs
                      value={this.state.tabValue}
                      onChange={this.handleTabChange}
                      textColor="secondary"
                      indicatorColor="secondary"
                      aria-label="secondary tabs example"
                    >
                      <Tab value="active" label="ACTIVE" />
                      <Tab value="pending" label={<span>PENDING <br />(Allotment)</span>} />
                      <Tab value="archives" label="ARCHIVES" />
                    </Tabs>
                  </Box>
                }

                {
                  this.state.display_mode !== 'scheme_action_completed' &&
                  <ListGroup >
                    {(this.state._list_xsip_orders || []).map((item, index) => (
                      <ListGroup.Item
                        key={index}
                        style={{
                          marginBottom: 10, border: '1px solid #a8acb3',
                          borderCollapse: 'collapse'
                        }}
                        action
                      >

                        {/* <table style={{ width: '100%' }}>
                          <tr>
                            <td style={{ backgroundColor: '#f7f5f5', border: '1px solid #e2e0e4', textAlign: 'center' }}>
                              <span >
                                Sip#
                              </span>
                            </td>
                            <td style={{ backgroundColor: '#f7f5f5', border: '1px solid #e2e0e4', textAlign: 'center' }}>
                              <span >
                                Amount
                              </span>
                            </td>
                            <td style={{ backgroundColor: '#f7f5f5', border: '1px solid #e2e0e4', textAlign: 'center' }}>
                              <span >
                                Frequency
                              </span>
                            </td><td style={{ backgroundColor: '#f7f5f5', border: '1px solid #e2e0e4', textAlign: 'center' }}>
                              <span >
                                Installments
                              </span>
                            </td>
                          </tr>

                          <tr>
                            <td style={{ height: 30, border: '1px solid #e2e0e4', textAlign: 'center' }}>
                              <span style={{
                                margin: 4
                              }}>
                                {item["mf_order_registration_no"]}
                              </span>
                            </td>
                            <td style={{ height: 30, border: '1px solid #e2e0e4', textAlign: 'center' }}>
                              <span style={{
                                margin: 4
                              }}>
                                <span style={{ fontFamily: 'sans-serif' }}>&#8377;</span>
                                {item["mf_order_installment_amount"] || ``}
                              </span>
                            </td>
                            <td style={{ height: 30, border: '1px solid #e2e0e4', textAlign: 'center' }}>
                              <span style={{
                                margin: 4
                              }} >
                                {item["mf_order_installment_frequency"] || ``}
                              </span>
                            </td>
                            <td style={{ height: 30, border: '1px solid #e2e0e4', textAlign: 'center' }}>
                              <span>
                                {item["mf_order_no_of_installments"] || ``}
                              </span>
                            </td>
                          </tr>
                        </table> */}

                        <table style={{ width: '100%' }}>
                          <tr>
                            <td style={{ backgroundColor: '#f7f5f5', border: '1px solid #e2e0e4', textAlign: 'right' }}>
                              <span style={{
                                margin: 4
                              }} >
                                Sip#
                              </span>
                            </td>
                            <td style={{ height: 30, border: '1px solid #e2e0e4', textAlign: 'center' }}>
                              <span style={{
                                margin: 4
                              }}>
                                {item["mf_order_registration_no"]}
                              </span>
                            </td>
                            <td style={{ backgroundColor: '#f7f5f5', border: '1px solid #e2e0e4', textAlign: 'right' }}>
                              <span style={{
                                margin: 4
                              }} >
                                Amount
                              </span>
                            </td>
                            <td style={{ height: 30, border: '1px solid #e2e0e4', textAlign: 'center' }}>
                              <span style={{
                                margin: 4
                              }}>
                                <span style={{ fontFamily: 'sans-serif' }}>&#8377;</span>
                                {item["mf_order_installment_amount"] || ``}
                              </span>
                            </td>
                          </tr>

                          <tr>


                            <td style={{ backgroundColor: '#f7f5f5', border: '1px solid #e2e0e4', textAlign: 'right' }}>
                              <span style={{
                                margin: 4
                              }} >
                                Frequency
                              </span>
                            </td>
                            <td style={{ height: 30, border: '1px solid #e2e0e4', textAlign: 'center' }}>
                              <span style={{
                                margin: 4
                              }} >
                                {item["mf_order_installment_frequency"] || ``}
                              </span>
                            </td>
                            <td style={{ backgroundColor: '#f7f5f5', border: '1px solid #e2e0e4', textAlign: 'right' }}>
                              <span style={{
                                margin: 4
                              }} >
                                Installments
                              </span>
                            </td>
                            <td style={{ height: 30, border: '1px solid #e2e0e4', textAlign: 'center' }}>
                              <span>
                                {item["mf_order_no_of_installments"] || ``}
                              </span>
                            </td>
                          </tr>
                        </table>

                        <Row style={{ marginBottom: 10 }}>
                        </Row>
                        <table style={{ width: '100%' }}>
                          <tr>
                            <td class="col-6" style={{ backgroundColor: '#f7f5f5', height: 30, border: '1px solid #e2e0e4', textAlign: 'center' }}>
                              <span >
                                1st Payment Date
                              </span>
                            </td>
                            <td class="col-4" style={{ height: 30, border: '1px solid #e2e0e4', textAlign: 'center' }}>
                              <span >
                                {item['installment_start_date']}
                              </span>
                            </td>
                            {
                            item['is_order_cancelled'] &&
                            <td class="col-4">
                              <span style={{
                                color: 'InfoText',
                                backgroundColor: '#ec9fa4',
                                fontWeight: 400,
                                padding: 4, paddingLeft: 8, paddingRight: 8, margin: 4, borderRadius: 4
                              }}>
                                {`CANCELLED`}
                              </span>
                            </td>
                          }
                          </tr>
                          {!item['is_order_cancelled'] && item["mf_order_folio_no"].length > 4 &&
                            <tr>
                              <td class="col-6" style={{ backgroundColor: '#f7f5f5', height: 30, border: '1px solid #e2e0e4', textAlign: 'center' }}>
                                <span >
                                  Folio Number
                                </span>
                              </td>
                              <td style={{ height: 30, border: '1px solid #e2e0e4', textAlign: 'center' }}>
                                <span style={{
                                  backgroundColor: item['mf_order_status'] === 'CXL' ? '#edf5ef' : '#edf5ef',
                                  borderRadius: 4
                                }}>
                                  {item["mf_order_folio_no"]}
                                </span>
                              </td>
                            </tr>
                          }

                        </table>

                        {/* <div class="row">
                          {
                            item['is_order_cancelled'] &&
                            <div class="col-6">
                              <span style={{
                                color: 'InfoText',
                                backgroundColor: '#ec9fa4',
                                fontWeight: 500,
                                padding: 4, paddingLeft: 8, paddingRight: 8, margin: 4, borderRadius: 4
                              }}>
                                {`CANCELLED`}
                              </span>
                            </div>
                          }

                        </div> */}
                        
                        <Row style={{ marginBottom: 5 }}>
                        </Row>

                        <table style={{ width: '100%' }}>
                          <tr>
                            <td style={{ textAlign: 'center' }}>
                              <span >
                                {(item["mf_order_scheme_name"] || "").replace(new RegExp("-", 'g'), '')}
                              </span>
                            </td>
                          </tr>
                        </table>

                        <Row style={{ marginBottom: 4 }}>
                        </Row>

                        {
                          item['mf_order_swp_enabled'] &&

                          <table style={{ width: '100%' }}>
                            <tr>
                              <td onClick={() => {
                                this.showSwpInformation(
                                  "xsip",
                                  item,
                                  "show_swp_cancel_modal"
                                );
                              }} style={{ height: 30, backgroundColor: '#dbc7ee', border: '1px solid #e2e0e4', textAlign: 'center' }}>
                                <span>
                                  {` `} SWP ENABLED
                                </span>
                              </td>
                              <td style={{ height: 30, border: '1px solid #e2e0e4', textAlign: 'center' }}>
                                <span >
                                  {item["mf_order_swp_information"]['frequency']}
                                </span>
                              </td>
                              <td style={{ height: 30, backgroundColor: '#dbc7ee', border: '1px solid #e2e0e4', textAlign: 'center' }}>
                                <span >
                                  <span style={{ fontFamily: 'sans-serif' }}>&#8377;</span>
                                  {item["mf_order_swp_information"]['amount']}
                                </span>
                              </td>
                            </tr>

                            <Row style={{ marginBottom: 10 }}>
                            </Row>

                          </table>
                        }
                        {
                          item['mf_order_pause_enabled'] &&
                          <div>
                            <table style={{ width: '100%' }}>
                              <tr
                                onClick={() => {
                                  this.setState({
                                    show_popup: true,
                                    popup_headerText: item['mf_order_pause_message_header'],
                                    popup_messageText:
                                      item['mf_order_pause_message_text'],
                                  })
                                }}>
                                <td style={{ height: 30, backgroundColor: '#FA8072', border: '1px solid #e2e0e4', textAlign: 'center' }}>
                                  <span>
                                    {` `} PAUSED ENABLED
                                  </span>
                                </td>
                                <td style={{ height: 30, border: '1px solid #e2e0e4', textAlign: 'center' }}>
                                  <span >
                                    Installments
                                  </span>
                                </td>
                                <td style={{ height: 30, textAlign: 'center' }}>
                                  <span className="circleNumberCount">
                                    {item['mf_order_paused_installments']}
                                  </span>
                                </td>
                              </tr>
                            </table>

                            <table style={{ width: '100%' }}>
                              <tr>
                                <td style={{ border: '1px solid #e2e0e4', textAlign: 'center' }}>
                                  {item['mf_order_pause_message_header']}
                                  <Button
                                    style={{ marginTop: -10, marginLeft: -15 }}
                                    className="text-success"
                                    variant="link" onClick={() => {
                                      this.setState({
                                        show_popup: true,
                                        popup_headerText: item['mf_order_pause_message_header'],
                                        popup_messageText:
                                          item['mf_order_pause_message_text'],
                                      })
                                    }}>
                                    Why? </Button>
                                </td>
                              </tr>
                            </table>
                          </div>
                        }

                        {item['mf_order_message_is_showed'] &&
                          <table style={{ width: '100%' }}>
                            <tr>
                              <td style={{ border: '1px solid #e2e0e4', textAlign: 'center' }}>
                                {item["mf_order_message"]} {`  `}
                                {
                                  !item['mf_order_is_allotted'] &&
                                  <Button
                                    style={{ marginTop: -3, marginLeft: -15 }}
                                    className="text-success"
                                    variant="link" onClick={() => {
                                      this.setState({
                                        show_popup: true,
                                        popup_headerText: item['modal_rta_validation_information']['header'],
                                        popup_messageText:
                                          item['modal_rta_validation_information']['body'],
                                      })
                                    }}>
                                    Why? </Button>
                                }
                              </td>
                            </tr>
                          </table>
                        }

                        {parseFloat(item['mf_order_overall_investment_amount']) > 1 ?
                          <table style={{ width: '100%' }}>
                            <tr>
                              <td style={{ height: 30, width: '50px', backgroundColor: '#f7f5f5', border: '1px solid #e2e0e4', textAlign: 'left' }}>
                                <span style={{
                                  margin: 4
                                }}>
                                  Invested
                                </span>
                              </td>
                              <td style={{ height: 30, border: '1px solid #e2e0e4', textAlign: 'center' }}>
                                <span style={{
                                  margin: 4
                                }}>
                                  <span style={{ fontFamily: 'sans-serif' }}>&#8377;</span>
                                  {item['mf_order_overall_investment_amount']}
                                </span>
                              </td>
                              <td style={{ height: 30, width: '50px', backgroundColor: '#f7f5f5', border: '1px solid #e2e0e4', textAlign: 'left' }}>
                                <span style={{
                                  margin: 4
                                }}>
                                  {parseFloat(item['returns_difference_amount'] || 0) < 0 ? `Loss    ` : `Profit  `}
                                </span>
                              </td>
                              <td style={{ height: 30, border: '1px solid #e2e0e4', textAlign: 'center' }}>
                                <span style={{
                                  margin: 4,
                                  color:
                                    parseFloat(item['returns_difference_amount'] || 0) < 0
                                      ? "#f57362"
                                      : parseFloat(item['returns_difference_amount'] || 0) === 0 ? '' : 'green',
                                }}>
                                  <span style={{ fontFamily: 'sans-serif' }}>&#8377;</span>
                                  {
                                    item['returns_difference_amount'] || 0
                                  }
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td style={{ height: 30, width: '50px', backgroundColor: '#f7f5f5', border: '1px solid #e2e0e4', textAlign: 'right' }}>

                                <span style={{
                                  margin: 4
                                }}>
                                  Returns
                                </span>
                              </td>
                              <td style={{ height: 30, border: '1px solid #e2e0e4', textAlign: 'center' }}>
                                <span style={{
                                  margin: 4,
                                  color: parseFloat(item['total_returns_amount'] || 0) -
                                    parseFloat(item['mf_order_overall_investment_amount']) < 0
                                    ? "#f57362"
                                    : (parseFloat(item['total_returns_amount'] || 0) -
                                      parseFloat(item['mf_order_overall_investment_amount'])) === 0 ? '' : 'green',
                                }}>
                                  <span style={{ fontFamily: 'sans-serif' }}>&#8377;</span>
                                  {item['total_returns_amount']}
                                </span>
                              </td>
                              <td style={{ height: 30, width: '50px', backgroundColor: '#f7f5f5', border: '1px solid #e2e0e4', textAlign: 'right' }}>
                                <span style={{
                                  margin: 4,
                                }}>
                                  Percent
                                </span>
                              </td>
                              <td style={{ height: 30, width: '50px', border: '1px solid #e2e0e4', textAlign: 'right' }}>
                                <span style={{
                                  fontFamily: 'Verdana, sans-serif',
                                  margin: 4,
                                  fontWeight: 500,
                                  color:
                                    parseFloat(item['returns_difference_amount']) < 0
                                      ? "#f57362"
                                      : (parseFloat(item['returns_difference_amount']) === 0 ? '' : 'green'),
                                }}>
                                  {parseFloat(item['returns_difference_amount']) > 0 ? `+` : ``}
                                  {item['total_returns_percent']}%</span>
                              </td>
                            </tr>
                          </table>
                          :
                          <span></span>
                        }

                        {/* <div class="row">
                          {parseFloat(item['mf_order_overall_investment_amount']) > 1 ?
                            <div class="col-6">

                              <div class="row">
                                <div class="col-4">
                                  Invested
                                </div>
                                <div class="col-8">
                                  <span style={{
                                    // backgroundColor: '#f2f7fa',
                                    fontWeight: 500,
                                    padding: 4, margin: 4, borderRadius: 4
                                  }}>
                                    <span style={{ fontFamily: 'sans-serif' }}>&#8377;</span>
                                    {item['mf_order_overall_investment_amount']}
                                  </span>
                                </div>
                                <div style={{ height: 5 }} />
                                <div class="row">
                                  <div class="col-4">
                                    Returns
                                  </div>
                                  <div class="col-8">
                                    <span style={{
                                      // backgroundColor: '#f2f7fa',
                                      padding: 4, paddingLeft: 12, paddingRight: 8, margin: 4, borderRadius: 4,
                                      color: parseFloat(item['total_returns_amount'] || 0) -
                                        parseFloat(item['mf_order_overall_investment_amount']) < 0
                                        ? "#f57362"
                                        : (parseFloat(item['total_returns_amount'] || 0) -
                                          parseFloat(item['mf_order_overall_investment_amount'])) === 0 ? '' : 'green',
                                    }}>
                                      <span style={{ fontFamily: 'sans-serif' }}>&#8377;</span>
                                      {item['total_returns_amount']}
                                    </span>

                                  </div>
                                </div>
                              </div>

                            </div>
                            :
                            <div class="col">
                            </div>
                          }

                          {parseFloat(item['mf_order_overall_investment_amount']) > 1 &&
                            <div class="col-6">

                              <div class="row">
                                <div class="col-4">
                                  {parseFloat(item['returns_difference_amount'] || 0) < 0 ? `Loss` : 'Profit'}
                                </div>
                                <div class="col-8">
                                  <span style={{
                                    padding: 4, margin: 4, borderRadius: 4,
                                    color:
                                      parseFloat(item['returns_difference_amount'] || 0) < 0
                                        ? "#f57362"
                                        : parseFloat(item['returns_difference_amount'] || 0) === 0 ? '' : 'green',
                                  }}>
                                    <span style={{ fontFamily: 'sans-serif' }}>&#8377;</span>
                                    {
                                      item['returns_difference_amount'] || 0
                                    }
                                  </span>
                                </div>
                              </div>
                              <div style={{ height: 5 }} />
                              <div class="row">
                                <div class="col-4">
                                  Returns%
                                </div>
                                <div class="col-8">
                                  <span style={{
                                    fontFamily: 'Verdana, sans-serif',
                                    padding: 4, paddingLeft: 8, paddingRight: 8, margin: 4, borderRadius: 4,
                                    color:
                                      parseFloat(item['returns_difference_amount']) < 0
                                        ? "#f57362"
                                        : (parseFloat(item['returns_difference_amount']) === 0 ? '' : 'green'),
                                  }}>
                                    {parseFloat(item['returns_difference_amount']) > 0 ? `+` : ``}
                                    {item['total_returns_percent']}</span>
                                </div>
                              </div>

                            </div>
                          }
                        </div> */}

                        <Row style={{ marginBottom: 7 }}>
                        </Row>

                        {!item['mf_order_is_cancelled'] &&
                          <table style={{ width: '100%', }}>
                            <tr>
                              <td style={{ height: 30, textAlign: 'left' }}>
                                <button type="button" class="btn-sm btn-success"
                                  style={{ color: 'white' }}
                                  onClick={() => {
                                    this.setState({
                                      show_modal_action: true,
                                      modal_action_order: item
                                    });
                                  }}
                                  disabled={this.state.isloading}>
                                  {item['mf_order_allotment_list'].length > 0 ?
                                    `Explore` : `Show History`
                                  }
                                </button>
                              </td>
                              {item['mf_order_is_invest_more'] &&
                                <td style={{ height: 30, textAlign: 'center' }}>

                                  <button type="button"
                                    class="btn-sm btn-info"
                                    style={{ color: 'white' }}
                                    onClick={() => {
                                      this.gotoCheckOutPage(
                                        "invest_more",
                                        item
                                      );
                                    }}
                                  >
                                    Invest More
                                  </button>
                                </td>
                              }
                              {item["is_redeem_allowed"] &&
                                <td style={{ height: 30, textAlign: 'center' }}>
                                  <button type="button"
                                    style={{ marginLeft: -5, color: 'white', borderColor: '#DC9D89', backgroundColor: '#DC9D89' }}
                                    class="btn-sm btn-warning"
                                    onClick={() => {
                                      this.gotoCheckOutPage("redeem", item);
                                    }}
                                  >
                                    Redeem
                                  </button>
                                </td>
                              }
                              <td style={{ height: 30, textAlign: 'right' }}>
                                <button type="button" class="btn-sm btn-info"
                                  style={{ color: 'white' }}
                                  onClick={() => {
                                    this.refreshOrder(item, false);
                                  }}
                                  disabled={this.state.isloading}>
                                  Refresh
                                </button>
                              </td>
                            </tr>
                          </table>
                        }

                        {/* {!item["mf_order_is_cancelled"] &&
                          <div class="row" >
                            <div class={"col-4"}>
                              <button type="button" class="btn-sm btn-success"
                                style={{ color: 'white' }}
                                onClick={() => {
                                  this.setState({
                                    show_modal_action: true,
                                    modal_action_order: item
                                  });
                                }}
                                disabled={this.state.isloading}>
                                Explore
                              </button>
                            </div>
                            <div class="col-4">
                              {item["is_redeem_allowed"] &&
                                <button type="button"
                                  class="btn-sm btn-warning"
                                  onClick={() => {
                                    this.gotoCheckOutPage("redeem", item);
                                  }}
                                >
                                  Redeem
                                </button>
                              }
                            </div>
                            {
                              <div class={"col-4"}>
                                <button type="button" class="btn-sm btn-info"
                                  style={{ color: 'white' }}
                                  onClick={() => {
                                    this.refreshOrder(item, false);
                                  }}
                                  disabled={this.state.isloading}>
                                  Refresh
                                </button>
                              </div>
                            }
                          </div>
                        } */}

                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                }

                {(this.state._list_xsip_orders || []).length === 0 &&
                  !this.state.isloading && this.state.display_mode !== 'scheme_action_completed' &&
                  <Alert variant="secondary text-center">
                    <Alert.Heading>
                      No orders found
                    </Alert.Heading>
                  </Alert>
                }

              </div>


            </div>

            <Modal
              show={
                this.state
                  .show_view_modal
              }
              onHide={() =>
                this.setState({ show_view_modal: false })
              }
              size="lg"
              // aria-labelledby="contained-modal-title-vcenter"
              centered
              keyboard={false}
              fullscreen={true}
              backdrop="static"
            >
              <Modal.Header closeButton>
                {this.state.modal_orderinfo['mf_order_scheme_name']}
              </Modal.Header>
              <Modal.Body>
                <div>
                  {this.state.isloading && (
                    <Spinner
                      animation="border"
                      variant="dark"
                      style={{
                        position: "fixed",
                        top: "50%",
                        left: "50%",
                        zIndex: 899,
                      }}
                    />
                  )}
                  {this.state.modal_orderinfo["show_child"] &&
                    (this.state.modal_orderinfo["display_installment_info_html"] || "")
                      .length > 7 && (
                      <div>
                        {/* <td > */}
                        <Card sx={{ backgroundColor: '#edf3f0', p: 1, borderRadius: 1 }}>
                          <div
                            dangerouslySetInnerHTML={this.createDefaultMarkup(
                              this.state.modal_orderinfo[
                              "display_installment_info_html"
                              ]
                            )}
                          />
                        </Card>

                        {/* </td> */}
                      </div>
                    )}
                </div>

              </Modal.Body>
              <Modal.Footer>
                
                {!this.state.show_modal_pin_confirm_dialog && 
                <div class="row">
                  {/* <div class="col-6">
                    <button type="button"
                      className="btn btn-outline-info btn-sm"
                      style={{ color: 'black' }}
                      onClick={() => {
                        this.onPauseOrder(
                          "xsip",
                          this.state.modal_orderinfo,
                          'initiate'
                        );

                      }}
                      disabled={!this.state.modal_orderinfo["is_pause_allowed"]} >
                      Pause Order
                    </button>
                  </div> */}
                  <div class="col-6">

                    <button type="button"
                      className="btn btn-outline-danger btn-sm"
                      style={{ color: 'black' }}
                      onClick={() => {
                        /*
                        this.onCancelSip(
                          "xsip",
                          this.state.modal_orderinfo
                        );
                        */
                       
                        this.sendOTP('cancel_sip');
                        /*
                        this.setState({
                          show_modal_pin_confirm_dialog: true,
                          modal_pin_confirm_action: 'cancel sip',
                          two_factor_authentication: {
                            is_otp_enabled: false,
                            mobile_otp: '',
                            email_otp: '',
                            twofactor_token_id: ''
                          }
                        })
                        */
                      }}
                      disabled={this.state.modal_orderinfo["is_cancel_allowed"]}
                    >
                      Cancel SIP
                    </button>
                  </div>
                  <div class="col-2"></div>
                </div>
                }
              </Modal.Footer>
            </Modal>

            {this.state
              .show_popup && (
                <Modal
                  show={
                    this.state
                      .show_popup
                  }
                  onHide={() =>
                    this.closePopup()
                  }
                  size="lg"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                >
                  <Modal.Header>
                    <b>
                      {this.state.popup_headerText}
                    </b>
                  </Modal.Header>
                  <Modal.Body>
                    <div style={{ margin: 0, padding: 0, fontSize: 12 }}
                      dangerouslySetInnerHTML={this.createDefaultMarkup(
                        this.state.popup_messageText
                      )}
                    />

                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      variant="primary"
                      onClick={() =>
                        this.closePopup()
                      }
                    >
                      Close
                    </Button>
                  </Modal.Footer>
                </Modal>
              )}

            {this.state.show_pause_popup && this.state.selected_pause_order['order_id'] &&
              <Modal
                show={this.state.show_pause_popup && this.state.selected_pause_order['order_id']}
                onHide={
                  () => this.setState({
                    show_pause_popup: false,
                    modal_pin_confirm_action: '',
                    modal_pin_confirm_value: ''
                  })

                }
                fullscreen={true}
                centered
              >
                <Modal.Header>
                  <h5 style={{ textAlign: "center" }}>Pause Order</h5>
                </Modal.Header>
                <Modal.Body>
                  {this.state.isloading && (
                    <Spinner
                      animation="border"
                      variant="dark"
                      style={{
                        position: "fixed",
                        top: "50%",
                        left: "50%",
                      }} />)}

                  <Card sx={{ backgroundColor: '#edf3f0', p: 1, borderRadius: 1 }}>
                    <label>
                      {this.state.selected_pause_order['mf_order_scheme_name']}
                    </label>
                    <label>
                      Sip Number : {this.state.selected_pause_order['mf_order_registration_no']}
                    </label>
                  </Card>
                  <hr />
                  <div class="row">
                    <div className="form-group">
                      <label style={{ fontSize: 15 }}>Choose number of installments</label>
                      <div style={{ height: 10 }} />
                      <Select
                        value={this.state.selected_pause_order['installment_list'].find(object =>
                          object.value === this.state.selected_pause_order['no_of_installments'] || '')
                        }
                        onChange={(e) => {
                          this.setState({
                            selected_pause_order:
                              { ...this.state.selected_pause_order, no_of_installments: e.value },
                            pause_order:
                              { ...this.state.pause_order, no_of_installments: e.value }
                          })
                        }
                        }
                        options={this.state.selected_pause_order['installment_list']}
                        isDisabled={this.state.modal_pin_confirm_action === 'pause order'}
                      />
                    </div>
                  </div>
                  {!this.state.pause_order['is_otp_enabled'] && (
                    <div>
                      <br />
                      <div className="text-center" style={{ marginTop: 10 }}>
                        <Button
                          className="btn btn-success btn-block"
                          variant="success"
                          onClick={() =>
                            this.sendOTP('pause_order', 'enable pause order')
                          }
                          disabled={
                            this.state.isloading ||
                            isNaN(this.state.pause_order['no_of_installments']) ||
                            this.state.pause_order['no_of_installments'].length < 1 ||
                            parseInt(this.state.pause_order['no_of_installments']) < 1
                          }
                        >
                          Submit
                        </Button>
                      </div>
                    </div>
                  )}


                  {
                    // this.state.modal_pin_confirm_action === 'pause order' &&
                    this.state.pause_order['is_otp_enabled'] &&
                    <div>
                      <div style={{ height: 10 }} />
                      <div className="row">
                        <div class="col">
                          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <label>Enter OTP received on your registered mobile</label>
                            <OTPInput
                              value={this.state.pause_order['two_factor_authentication']['mobile_otp']}
                              onChange={this.handle2FactorChange('mobile_otp', 'two_factor_authentication', 'pause_order')}
                              autoFocus
                              OTPLength={6}
                              otpType="number"
                              secure
                              isInputNum={true}
                            />
                          </Form.Group>
                        </div>
                        {
                          this.state.is_otp_email_required &&
                          <Form.Group
                            className="mb-3"
                          >
                            <Form.Label>Enter OTP received on your registered email</Form.Label>
                            <OTPInput
                              value={this.state.pause_order['two_factor_authentication']['email_otp']}
                              onChange={this.handle2FactorChange('email_otp', 'two_factor_authentication', 'pause_order')}
                              OTPLength={6}
                              otpType="number"
                              secure
                              isInputNum={true}
                            />
                          </Form.Group>
                        }
                        <br />
                        <div className="row">
                          <ResendOTP maxTime={120} renderButton={renderButton} style={{ color: 'black', marginTop: 20 }} renderTime={renderTime}
                            onResendClick={(e) => { this.sendOTP() }}
                          />
                        </div>
                      </div>
                      <div style={{ height: 10 }} />

                    </div>
                  }


                </Modal.Body>
                <Modal.Footer>
                  <table style={{ width: '100%' }}>
                    <tr>
                      <td style={{ width: 160, height: 30, textAlign: 'left' }}>
                        <button
                          className="btn btn-warning btn-sm"
                          style={{ width: 100 }}
                          onClick={() =>
                            this.setState({
                              show_pause_popup: false,
                              modal_pin_confirm_action: '',
                              modal_pin_confirm_value: '',
                              pause_order: {
                                display_text: '',
                                is_otp_enabled: false,
                                two_factor_authentication: {
                                  mobile_otp: '',
                                  email_otp: '',
                                  twofactor_token_id: ''
                                }
                              },
                              modal_orderinfo: {}
                            })
                          }
                        >
                          Close
                        </button>
                      </td>
                      <td style={{ height: 30, textAlign: 'right' }}>
                        {this.state.pause_order['is_otp_enabled'] &&
                          <button
                            className="btn btn-success btn-sm"
                            onClick={() =>
                              this.onPauseOrder(
                                "xsip",
                                Object.assign(this.state.selected_pause_order,
                                  { pin_verification_value: this.state.modal_pin_confirm_value }),
                                'confirm'
                              )
                            }
                            disabled={
                              this.state.isloading ||
                              isNaN(this.state.pause_order['no_of_installments']) ||
                              this.state.pause_order['no_of_installments'].length < 1 ||
                              parseInt(this.state.pause_order['no_of_installments']) < 1 ||
                              (this.state.is_otp_email_required && this.state.pause_order['two_factor_authentication']['email_otp'].length < 6) ||
                              this.state.pause_order['two_factor_authentication']['mobile_otp'].length < 6

                            }
                          >
                            Confirm <i className="fa fa-arrow-right  fa-lg" ></i>
                          </button>
                        }
                      </td>
                    </tr>
                  </table>

                </Modal.Footer>

              </Modal>
            }

            {this.state.show_swp_modal &&
              <Modal
                show={
                  this.state
                    .show_swp_modal
                }
                onHide={() =>
                  this.setState({
                    show_swp_modal: false,
                    modal_orderinfo: {},
                    swp_order: {
                      ...this.state.swp_order,
                      is_otp_enabled: false,
                      two_factor_authentication: {
                        mobile_otp: '',
                        email_otp: '',
                        twofactor_token_id: ''
                      }
                    }
                  })
                }
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                keyboard={false}
                fullscreen={true}
                backdrop="static"
              >
                <Modal.Header closeButton>
                  <h4>Systematic Withdrawal Plan </h4>

                </Modal.Header>
                <Modal.Body>
                  <div>
                    {this.state.isloading && (
                      <Spinner
                        animation="border"
                        variant="dark"
                        style={{
                          position: "fixed",
                          top: "50%",
                          left: "50%",
                          zIndex: 899,
                        }}
                      />
                    )}
                    <p>
                      {this.state.modal_orderinfo['mf_order_scheme_name']}
                    </p>
                    <div className="row">
                      <div class="col-6">
                        <div class="form-group">
                          <p class="mr-sm-2 text-gray-dark" style={{ marginBottom: 0 }}>No Of Withdrawals</p>

                          <Select
                            value={(this.state.modal_orderinfo['swp_information']['installment_numbers_list'] || []).find(obj => obj.value === this.state.swp_order['swp_no_of_installments'] || '')}
                            onChange={(e) => this.handleChangeSelect(e, this.state.modal_orderinfo, 'swp_no_of_installments', 'swp')}
                            options={this.state.modal_orderinfo['swp_information']['installment_numbers_list']}
                            isSearchable={false}
                            isDisabled={this.state.swp_order['is_otp_enabled']}
                          />
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form-group">
                          <p class="mr-sm-2 text-gray-dark" style={{ marginBottom: 0 }}>Choose Frequency</p>
                          {this.state.modal_orderinfo['swp_information']['frequency_list'] &&
                            <Select
                              value={this.state.modal_orderinfo['swp_information']['frequency_list'].find(obj => obj.value === this.state.swp_order['swp_frequency'] || '')}
                              onChange={(e) => this.handleChangeSelect(e, this.state.modal_orderinfo, 'swp_frequency', 'swp')}
                              options={this.state.modal_orderinfo['swp_information']['frequency_list']}
                              isSearchable={false}
                              isDisabled={this.state.swp_order['is_otp_enabled']}
                            />
                          }
                        </div>
                      </div>
                    </div>
                    <br />
                    <div className="row">
                      <div class="col-10">
                        <div class="form-group">
                          <p class="mr-sm-2 text-gray-dark" style={{ marginBottom: 0 }}>Enter Amount</p>
                          <input
                            // style={{ width: 200 }}
                            type="tel"
                            pattern="[0-9]*"
                            name="swp_amount"
                            class="form-control"
                            placeholder=""
                            maxLength="7"
                            value={this.state.swp_order['swp_amount']}
                            disabled={this.state.swp_order['is_otp_enabled']}
                            onKeyPress={event => {
                              if (event.target.key === 'Enter') {
                                event.preventDefault();
                              }
                            }}
                            onChange={event => {
                              const targetValue = event.target.value.replace(/\s/g, '');

                              if (targetValue.length === 0)
                                this.setState({ swp_order: { ...this.state.swp_order, swp_amount: targetValue } });
                              else {
                                if (targetValue.match(/^[0-9]+$/)) {
                                  if (parseFloat(targetValue) <= parseFloat(this.state.modal_orderinfo['swp_information']['maximum_amount']))
                                    this.setState({ swp_order: { ...this.state.swp_order, swp_amount: targetValue } });

                                }

                              }

                            }}
                            style={{ letterSpacing: 7 }}
                          />
                          <ul className="list-horizontal">
                            <li>
                              <span style={{ color: `#733dd1` }}>
                                Min.
                                <span style={{ fontFamily: 'sans-serif' }}>&#8377;</span>
                                {this.state.modal_orderinfo['swp_information']['minimum_amount']} {`  `}</span>
                            </li>
                            <li>
                              <span style={{ color: `#0f967b` }}>Max.
                                <span style={{ fontFamily: 'sans-serif' }}>&#8377;</span>
                                {this.state.modal_orderinfo['swp_information']['maximum_amount']}
                              </span>
                            </li>
                          </ul>

                        </div>
                      </div>
                    </div>
                    <br />
                    <div className="row">
                      <div className="col-10">
                        <div class="form-group">
                          <p class="mr-sm-2 text-gray-dark" style={{ marginBottom: 0 }}>SWP Date</p>

                          <input
                            type="text"
                            name="swp_date"
                            class="form-control"
                            placeholder=""
                            maxLength="2"
                            readOnly={true}
                            value={this.state.swp_order['swp_date_value']}
                            onClick={event => {
                              this.setState({
                                show_swpdate_popup: true,
                                popup_swp_date_list: this.state.modal_orderinfo['swp_information']['swp_date_list']
                              })
                            }}
                            disabled={
                              (this.state.swp_order['swp_frequency'] || '').toString().length < 4
                              || this.state.swp_order['is_otp_enabled']}

                          />
                        </div>
                      </div>
                    </div>
                    {this.state.swp_order['swp_date_text'].toString().length > 10 &&
                      <div className="row">
                        <p style={{ color: `#733dd1`, marginBottom: 0 }} >
                          Your 1st withdrawal will be placed on {new Date(this.state.swp_order['swp_date_text']).toLocaleString("en-IN", { year: 'numeric', month: 'short', day: 'numeric' })}
                        </p>
                      </div>
                    }
                    <br />
                    {!this.state.swp_order['is_otp_enabled'] &&
                      <div className="row">
                        <div class="col-3"></div>
                        <div className="col">
                          <button
                            className="btn btn-success btn-sm"
                            style={{ fontSize: 12, width: 130 }}
                            disabled={
                              this.state.isloading ||
                              isNaN(this.state.swp_order['swp_no_of_installments']) ||
                              isNaN(this.state.swp_order['swp_amount']) ||
                              (this.state.swp_order['swp_amount']).trim().length === 0 ||
                              (this.state.swp_order['swp_date'] || ``).toString().trim().length === 0 ||

                              parseInt(this.state.swp_order['swp_amount']) > parseInt(this.state.modal_orderinfo['swp_information']['maximum_amount']) ||
                              parseInt(this.state.swp_order['swp_amount']) < parseInt(this.state.modal_orderinfo['swp_information']['minimum_amount']) ||

                              this.state.swp_order['swp_frequency'].length < 5 ||
                              parseInt(this.state.swp_order['swp_no_of_installments']) < 1 ||
                              parseInt(this.state.swp_order['swp_date']) < 1 ||
                              this.state.swp_order['is_otp_enabled']
                            }
                            onClick={() => {
                              this.sendOTP()
                            }}
                          >
                            Register
                          </button>
                        </div>
                      </div>
                    }
                  </div>
                  {this.state.swp_order['is_otp_enabled'] &&
                    <div>
                      <hr />
                      <div className="row">
                        <div class="col">
                          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Enter OTP received on your registered mobile</Form.Label>
                            <OTPInput
                              value={this.state.swp_order['two_factor_authentication']['mobile_otp']}
                              onChange={this.handle2FactorChange('mobile_otp', 'two_factor_authentication', 'swp_order')}
                              autoFocus
                              OTPLength={6}
                              otpType="number"
                              secure
                              isInputNum={true}
                            />
                          </Form.Group>
                        </div>
                        {
                          this.state.is_otp_email_required &&
                          // <br />
                          <Form.Group
                            className="mb-3"
                          >
                            <Form.Label>Enter OTP received on your registered email</Form.Label>
                            <OTPInput
                              value={this.state.swp_order['two_factor_authentication']['email_otp']}
                              onChange={this.handle2FactorChange('email_otp', 'two_factor_authentication', 'swp_order')}
                              OTPLength={6}
                              otpType="number"
                              secure
                              isInputNum={true}
                            />
                          </Form.Group>
                        }
                        <br />
                        <div className="row">
                          <ResendOTP maxTime={120} renderButton={renderButton} style={{ color: 'black', marginTop: 20 }} renderTime={renderTime}
                            onResendClick={(e) => { this.sendOTP() }}
                          />
                        </div>
                        <div class="row">

                          <div class="col">
                            <hr />
                            <button
                              className="btn btn-success btn-sm"
                              onClick={() => {
                                this.onCreateSwpOrder("xsip", this.state.modal_orderinfo);
                              }}
                              disabled={
                                this.state.isloading ||
                                isNaN(this.state.swp_order['swp_no_of_installments']) ||
                                isNaN(this.state.swp_order['swp_amount']) ||
                                this.state.swp_order['swp_no_of_installments'].length < 1 ||
                                this.state.swp_order['swp_amount'].length < 1 ||
                                this.state.swp_order['swp_frequency'].length < 5 ||
                                parseInt(this.state.swp_order['swp_no_of_installments']) < 1 ||
                                parseInt(this.state.swp_order['swp_amount']) < parseInt(this.state.modal_orderinfo['minimum_swp_amount']) ||
                                (this.state.is_otp_email_required && this.state.swp_order['two_factor_authentication']['email_otp'].length < 6) ||
                                this.state.swp_order['two_factor_authentication']['mobile_otp'].length < 6

                              }
                            >
                              Confirm SWP
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                </Modal.Body>
                {/* <Modal.Footer>
                      <div className="row">
                        <div class="col">
                          <button
                            className="btn btn-success btn-sm"
                            style={{ fontSize: 12, width: 170 }}
                            onClick={() => {
                              this.setState({
                                show_modal_pin_confirm_dialog: true,
                                modal_pin_confirm_action: 'start swp',
                                modal_pin_confirm_value: ''
                              })
                            }}
                          >
                            Confirm
                          </button>
                        </div>
                        <div class="col">
                          <button
                            className="btn btn-warning btn-sm"
                            style={{ width: 100 }}
                            onClick={() =>
                              this.setState({ show_swp_modal: false })
                            }
                          >
                            Close
                          </button>
                        </div>
                      </div>
                    </Modal.Footer> */}
              </Modal>
            }
            {/* {
              (this.state._list_xsip_orders || []).length > 0 &&
              <Fab
                style={{ top: 48, left: 135, transform: 'scale(0.8)' }}
                alwaysShowTitle={true}
                mainButtonStyles={{
                  backgroundColor: "#a776c1"
                }}
                icon={<i className="mdi mdi-filter" ></i>}
                onClick={() => this.setState({ show_modal_filter: true })}
              >
              </Fab>
            } */}

            {this.state
              .show_modal_filter && (
                <Modal
                  show={
                    this.state
                      .show_modal_filter
                  }
                  onHide={() =>
                    this.setState({ show_modal_filter: false })
                  }
                  size="lg"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                >
                  <Modal.Header>
                    <h5 style={{ textAlign: 'center' }}>
                      Search with status
                    </h5>
                  </Modal.Header>
                  <Modal.Body>
                    <div >
                      {!this.state.loading && (
                        <div>
                          <Container>
                            <Row >
                              <Col>
                                <div class="form-group">
                                  <div
                                    style={{ textAlign: 'center' }} >
                                    <Select
                                      placeholder={`-CHOOSE STATUS-`}
                                      isMulti
                                      value={this.state.status_filter_selected}
                                      onChange={this.handleChangeStatus}
                                      options={this.state.status_filter_list}
                                    />
                                  </div>
                                </div>
                              </Col>
                            </Row>
                            <div
                              class="form-group"
                              style={{ marginBottom: 10, marginTop: 10 }}
                            ></div>
                          </Container>
                        </div>
                      )}
                    </div>

                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      variant="primary"
                      onClick={() =>
                        this.resetFilter('xsip')
                      }
                    >
                      Reset Filter
                    </Button>
                    {`      `}
                    <Button
                      variant="secondary"
                      onClick={() =>
                        this.setState({ show_modal_filter: false })
                      }
                    >
                      Done
                    </Button>
                  </Modal.Footer>
                </Modal>
              )}

            {this.state.show_modal_pin_confirm_dialog && (
              <Modal show={this.state.show_modal_pin_confirm_dialog}
                onHide={() => this.setState({
                  show_modal_pin_confirm_dialog: false,
                  modal_pin_confirm_action: '',
                  modal_pin_confirm_value: '',
                  two_factor_authentication: {
                    is_otp_enabled: false,
                    mobile_otp: '',
                    email_otp: '',
                    twofactor_token_id: ''
                  }
                })}
                centered
                size="lg"
                keyboard={false}
                fullscreen={true}
                backdrop="static"
              >
                <Modal.Header>

                </Modal.Header>
                <Modal.Body>
                {this.state.isloading && (
                      <Spinner
                        animation="border"
                        variant="dark"
                        style={{
                          position: "fixed",
                          top: "50%",
                          left: "50%",
                          zIndex: 899,
                        }}
                      />
                    )}  
                <div className="row">
                    <div class="col">
                      <p>{`Select a reason for cancelling *`}</p>
                    </div>
                  </div>
                  <div style={{ height: '10px' }} />
                  <div className="row">
                      <div class="col">
                        <Select
                          options={this.state.sip_cancellation_reason_list}
                          onChange={(e) => this.handleCancelSipReason(e)}
                          value={this.state.sip_cancellation_reason_list.find(obj => obj.value === this.state.cancellation_reason)}
                          isSearchable={false}
                        />
                      </div>
                    </div>
                    <div style={{ height: '10px' }} />
                    <div className="row">
                        <div class="col">
                          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <label>Enter OTP received on your registered mobile</label>
                            <OTPInput
                              value={this.state.cancel_sip['two_factor_authentication']['mobile_otp']}
                              onChange={this.handle2FactorChange('mobile_otp', 'two_factor_authentication', 'cancel_sip')}
                              autoFocus
                              OTPLength={6}
                              otpType="number"
                              secure
                              isInputNum={true}
                            />
                          </Form.Group>
                        </div>
                        {this.state.is_otp_email_required &&
                          <Form.Group
                            className="mb-3"
                          >
                            <Form.Label>Enter OTP received on your registered email</Form.Label>
                            <OTPInput
                              value={this.state.cancel_sip['two_factor_authentication']['email_otp']}
                              onChange={this.handle2FactorChange('email_otp', 'two_factor_authentication', 'cancel_sip')}
                              OTPLength={6}
                              otpType="number"
                              secure
                              isInputNum={true}
                            />
                          </Form.Group>
                        }
                        <br />
                        <div className="row">
                          <ResendOTP maxTime={90} renderButton={renderButton} style={{ color: 'black', marginTop: 20 }} renderTime={renderTime}
                            onResendClick={(e) => { this.sendOTP('cancel_sip') }}
                          />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                  {
                    this.state.modal_pin_confirm_action === 'cancel_sip' && 
                    <Button variant="primary"
                    disabled={this.state.cancel_sip['two_factor_authentication']['mobile_otp'].length < 6 ||
                      (this.state.is_otp_email_required && this.state.cancel_sip['two_factor_authentication']['email_otp'].length < 6) || 
                      (this.state.cancellation_reason['code'] || ``).length < 2
                    }
                    onClick={() => {
                        this.onCancelSip(
                          "xsip",
                          Object.assign(this.state.modal_action_order,
                            {
                              pin_verification_value: this.state.modal_pin_confirm_value,
                              cancellation_reason: this.state.cancellation_reason,
                              two_factor_authentication: this.state.cancel_sip['two_factor_authentication']
                            })
                        );
                    }}>
                    Confirm
                  </Button>
                  }
                  {
                    this.state.modal_pin_confirm_action === 'pause_order' && 
                    <Button variant="primary"
                    disabled={this.state.pause_order['two_factor_authentication']['mobile_otp'].length < 6 ||
                      (this.state.is_otp_email_required && this.state.pause_order['two_factor_authentication']['email_otp'].length < 6)
                    }
                    onClick={() => {
                      this.onPauseOrder(
                        "xsip",
                        Object.assign(this.state.selected_pause_order,
                          { 
                            pin_verification_value: this.state.modal_pin_confirm_value,
                            two_factor_authentication: this.state.pause_order['two_factor_authentication'] 
                          }),
                        'confirm'
                      );
                    }}>
                    Confirm
                  </Button>
                  }
                  {
                    this.state.modal_pin_confirm_action === 'cancel_swp' && 
                    <Button variant="primary"
                    disabled={this.state.cancel_swp['two_factor_authentication']['mobile_otp'].length < 6 ||
                      (this.state.is_otp_email_required && this.state.cancel_swp['two_factor_authentication']['email_otp'].length < 6)
                    }
                    onClick={() => {
                      this.onCancelSwpOrder(
                        "xsip",
                        Object.assign(this.state.modal_orderinfo, 
                          { 
                            pin_verification_value: this.state.modal_pin_confirm_value,
                            two_factor_authentication: this.state.cancel_swp['two_factor_authentication']
                          })
                      );
                    }}>
                    Confirm
                  </Button>
                  }
                  
                  <Button variant="primary"
                    onClick={() => this.setState({
                      show_modal_pin_confirm_dialog: false,
                      modal_pin_confirm_action: '',
                      modal_pin_confirm_value: '',
                      two_factor_authentication: {
                        is_otp_enabled: false,
                        mobile_otp: '',
                        email_otp: '',
                        twofactor_token_id: ''
                      }
                    })}>
                    Cancel
                  </Button>
                </Modal.Footer>
              </Modal>
            )}
          </div>
        </div>

        {this.state
          .show_swpdate_popup && (
            <Modal

              show={
                this.state
                  .show_swpdate_popup
              }
              onHide={() =>
                this.setState({ show_swpdate_popup: false })
              }

              size="sm"
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Header closeButton>Choose a monthly SIP date</Modal.Header>
              <Modal.Body>
                <div>
                  {[1, 6, 11, 16, 21, 26].map((item, index) => (
                    <div className="row" key={index}>
                      <br />
                      {[0, 1, 2, 3, 4].map((increment, _index) => (

                        <div class="col-2" key={_index}>
                          {
                            this.state.popup_swp_date_list.indexOf((item + increment).toString()) > -1 ?
                              <button type="button"
                                className="btn-sm btn-success"
                                style={{ color: 'white', width: '30px', marginTop: '3px', marginLeft: '3px' }}
                                onClick={() => {

                                  var firstSwpDate = new Date();
                                  firstSwpDate = firstSwpDate.setMonth(firstSwpDate.getMonth() + 1);

                                  this.setState({
                                    show_swpdate_popup: false,
                                    swp_order: {
                                      ...this.state.swp_order,
                                      swp_date: (item + increment),
                                      swp_date_value: moment('1982-11-' + (item + increment)).format('Do ')
                                        + ' of every ' + this.state.swp_order['swp_frequency'].toLowerCase().replace('ly', ''),
                                      swp_date_text: moment(new Date(firstSwpDate).setDate(parseInt((item + increment))))
                                    }
                                  })
                                }}
                                disabled={this.state.popup_swp_date_list.indexOf((item + increment).toString()) === -1}
                              >
                                {item + increment}
                              </button>
                              :
                              <div>
                                {(item + increment <= 28) &&
                                  <p
                                    style={{ width: '30px', textAlign: 'center', backgroundColor: '#dbcec5', padding: 4, paddingLeft: 8, paddingRight: 8, margin: 4, borderRadius: 4 }}>
                                    {item + increment}
                                  </p>
                                }
                              </div>
                          }
                        </div>

                      ))}
                    </div>
                  ))}
                </div>
              </Modal.Body>
            </Modal>
          )}
        {
          <Modal
            show={
              this.state
                .show_modal_action
            }
            onHide={() =>
              this.setState({
                show_modal_action: false,
                modal_action_name: ``
              })
            }
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            keyboard={false}
            backdrop="static"
          >
            <Modal.Header>
              <h5 style={{ textAlign: 'center' }}>
                {this.state.isloading ? `Processing your request...` : `Choose an action`}
              </h5>
            </Modal.Header>
            <Modal.Body>
              <div>
                {this.state.isloading ?
                  <Spinner
                    animation="border"
                    variant="dark"
                    style={{
                      position: "fixed",
                      top: "50%",
                      left: "50%",
                      zIndex: 899,
                    }}
                  />
                  :
                  (
                    <div>

                      <Container>
                        <Row >
                          <Col>
                            <div class="form-group">
                              <div class="row">
                                <div class="col">
                                  <Form.Check
                                    type="radio"
                                    label={
                                      <span>
                                        <i className="fa fa-history fa-lg"></i>
                                        <span style={{
                                          backgroundColor: '#f5f5f5',
                                          fontSize: 16,
                                          padding: 4, paddingLeft: 8, paddingRight: 8, margin: 4, borderRadius: 4
                                        }}>
                                          {`Show History`}
                                        </span>
                                      </span>
                                    }
                                    style={{ fontSize: 22 }}
                                    name="show_history"
                                    id={`show_history`}
                                    value={`show_history`}
                                    checked={
                                      this.state.modal_action_name === `show_history`
                                    }
                                    onChange={(e) =>
                                      this.setState({
                                        modal_action_name: `show_history`
                                      })
                                    }
                                    key={`action_show_history`}
                                  />
                                </div>
                              </div>
                              {this.state.modal_action_order["is_view_statement_allowed"] &&
                                <br />
                              }
                              {this.state.modal_action_order["is_view_statement_allowed"] &&
                                <div class="row">
                                  <div class="col">
                                    <Form.Check
                                      type="radio"
                                      label={
                                        <span >
                                          <i className="fa fa-file-pdf-o fa-lg"></i>
                                          <span style={{
                                            backgroundColor: '#f5f5f5',
                                            fontSize: 16,
                                            padding: 4, paddingLeft: 8, paddingRight: 8, margin: 4, borderRadius: 4
                                          }}>
                                            {`View Statement`}
                                          </span>
                                        </span>
                                      }
                                      style={{ fontSize: 22 }}
                                      name="view_statement"
                                      id={`view_statement`}
                                      value={`view_statement`}
                                      checked={
                                        this.state.modal_action_name === `view_statement`
                                      }
                                      onChange={(e) =>
                                        this.setState({
                                          modal_action_name: `view_statement`
                                        })
                                      }
                                      key={`action_view_statement`}
                                    />
                                    <hr />
                                  </div>
                                </div>
                              }

                              {parseFloat(this.state.modal_action_order['mf_order_overall_investment_amount']) > 1 &&
                                <br />
                              }
                              {parseFloat(this.state.modal_action_order['mf_order_overall_investment_amount']) > 1 &&
                                <div>

                                  <div class="row">
                                    <div class="col">
                                      <Form.Check
                                        type="radio"
                                        label={
                                          <span>
                                            <i className="fa fa-info fa-lg"></i>
                                            <span style={{
                                              backgroundColor: '#f5f5f5',
                                              fontSize: 16,
                                              padding: 4, paddingLeft: 8, paddingRight: 8, margin: 22, borderRadius: 4
                                            }}>
                                              {`More Info & Action`}
                                            </span>
                                          </span>
                                        }
                                        style={{ fontSize: 22 }}
                                        name="view_info"
                                        id={`view_info`}
                                        value={`view_info`}
                                        checked={
                                          this.state.modal_action_name === `view_info`
                                        }
                                        onChange={(e) =>
                                          this.setState({
                                            modal_action_name: `view_info`
                                          })
                                        }
                                        key={`action_view_info`}
                                      />
                                      <br />
                                    </div>
                                  </div>

                                  {this.state.modal_action_order['is_stp_allowed'] &&
                                    <div class="row">
                                      {this.state.modal_action_order['mf_order_stp_enabled'] ?
                                        <div class="col">
                                          <Form.Check
                                            type="radio"
                                            label={
                                              <span>
                                                <i className="la la-user"></i>
                                                <span style={{
                                                  backgroundColor: '#f5f5f5',
                                                  fontSize: 16,
                                                  padding: 4, paddingLeft: 8, paddingRight: 8, margin: 7, borderRadius: 4
                                                }}>
                                                  {`Cancel STP`}
                                                </span>
                                              </span>
                                            }
                                            style={{ fontSize: 22 }}
                                            name="cancel_stp"
                                            id={`cancel_stp`}
                                            value={`cancel_stp`}
                                            checked={
                                              this.state.modal_action_name === `cancel_stp`
                                            }
                                            onChange={(e) =>
                                              this.setState({
                                                modal_action_name: `cancel_stp`
                                              })
                                            }
                                            key={`action_cancel_stp`}
                                          />
                                          <br />
                                        </div>
                                        :
                                        <div class="col">
                                          <Form.Check
                                            type="radio"
                                            label={
                                              <span>
                                                <i className="fa fa-rocket fa-lg"></i>
                                                <span style={{
                                                  backgroundColor: '#f5f5f5',
                                                  fontSize: 16,
                                                  padding: 4, paddingLeft: 8, paddingRight: 8, margin: 7, borderRadius: 4
                                                }}>
                                                  {`Start STP`}
                                                </span>
                                              </span>
                                            }
                                            style={{ fontSize: 22 }}
                                            name="start_stp"
                                            id={`start_stp`}
                                            value={`start_stp`}
                                            checked={
                                              this.state.modal_action_name === `start_stp`
                                            }
                                            onChange={(e) =>
                                              this.setState({
                                                modal_action_name: `start_stp`
                                              })
                                            }
                                            key={`action_start_stp`}
                                          />
                                          <br />
                                        </div>
                                      }
                                    </div>
                                  }

                                  {this.state.modal_action_order['is_swp_allowed'] &&
                                    <div class="row">
                                      {this.state.modal_action_order['mf_order_swp_enabled'] ?
                                        <div class="col">
                                          <Form.Check
                                            type="radio"
                                            label={
                                              <span>
                                                <i className="la la-user"></i>
                                                <span style={{
                                                  backgroundColor: '#f5f5f5',
                                                  fontSize: 16,
                                                  padding: 4, paddingLeft: 8, paddingRight: 8, margin: 15, borderRadius: 4
                                                }}>
                                                  {`Cancel SWP`}
                                                </span>
                                              </span>
                                            }
                                            style={{ fontSize: 22 }}
                                            name="cancel_swp"
                                            id={`cancel_swp`}
                                            value={`cancel_swp`}
                                            checked={
                                              this.state.modal_action_name === `cancel_swp`
                                            }
                                            onChange={(e) =>
                                              this.setState({
                                                modal_action_name: `cancel_swp`
                                              })
                                            }
                                            key={`action_cancel_swp`}
                                          />
                                          <br />
                                        </div>
                                        :
                                        <div class="col">
                                          <Form.Check
                                            type="radio"
                                            label={
                                              <span>
                                                <i className="fa fa-inr fa-lg"></i>
                                                <span style={{
                                                  backgroundColor: '#f5f5f5',
                                                  fontSize: 16,
                                                  padding: 4, paddingLeft: 8, paddingRight: 8, margin: 20, borderRadius: 4
                                                }}>
                                                  {`Start SWP`}
                                                </span>
                                              </span>
                                            }
                                            style={{ fontSize: 22 }}
                                            name="start_swp"
                                            id={`start_swp`}
                                            value={`start_swp`}
                                            checked={
                                              this.state.modal_action_name === `start_swp`
                                            }
                                            onChange={(e) =>
                                              this.setState({
                                                modal_action_name: `start_swp`
                                              })
                                            }
                                            key={`action_start_swp`}
                                          />
                                          <br />
                                        </div>
                                      }
                                    </div>
                                  }

                                  {this.state.modal_action_order['mf_order_is_pause_allowed'] &&
                                    !this.state.modal_action_order['is_order_cancelled'] &&
                                    <div class="row">
                                      {this.state.modal_action_order['mf_order_pause_enabled'] ?
                                        <div class="col">
                                          <Form.Check
                                            type="radio"
                                            label={
                                              <span>
                                                <i className="la la-user"></i>
                                                <span style={{
                                                  backgroundColor: '#f5f5f5',
                                                  fontSize: 16,
                                                  padding: 4, paddingLeft: 8, paddingRight: 8, margin: 15, borderRadius: 4
                                                }}>
                                                  {`Disable Pause`}
                                                </span>
                                              </span>
                                            }
                                            style={{ fontSize: 22 }}
                                            name="disable_pause_order"
                                            id={`disable_pause_order`}
                                            value={`disable_pause_order`}
                                            checked={
                                              this.state.modal_action_name === `disable_pause_order`
                                            }
                                            onChange={(e) =>
                                              this.setState({
                                                modal_action_name: `disable_pause_order`
                                              })
                                            }
                                            key={`action_disable_pause_order`}
                                          />
                                          <br />
                                        </div>
                                        :
                                        <div class="col">
                                          <Form.Check
                                            type="radio"
                                            label={
                                              <span>
                                                <i className="fa fa-inr fa-lg"></i>
                                                <span style={{
                                                  backgroundColor: '#f5f5f5',
                                                  fontSize: 16,
                                                  padding: 4, paddingLeft: 8, paddingRight: 8, margin: 20, borderRadius: 4
                                                }}>
                                                  {`Pause SIP`}
                                                </span>
                                                <label style={{ fontSize: 16 }}>{`(Skip installments)`}</label>
                                              </span>
                                            }
                                            style={{ fontSize: 22 }}
                                            name="enable_pause_order"
                                            id={`enable_pause_order`}
                                            value={`enable_pause_order`}
                                            checked={
                                              this.state.modal_action_name === `enable_pause_order`
                                            }
                                            onChange={(e) =>
                                              this.setState({
                                                modal_action_name: `enable_pause_order`
                                              })
                                            }
                                            key={`action_enable_pause_order`}
                                          />
                                          <br />
                                        </div>
                                      }
                                    </div>
                                  }

{this.state.modal_action_order['is_cancel_allowed'] &&
                                    <div class="row">
                                        
                                        <div class="col">
                                          <Form.Check
                                            type="radio"
                                            label={
                                              <span>
                                                <i className="fa fa-inr fa-lg"></i>
                                                <span style={{
                                                  backgroundColor: '#f5f5f5',
                                                  fontSize: 16,
                                                  padding: 4, paddingLeft: 8, paddingRight: 8, margin: 20, borderRadius: 4
                                                }}>
                                                  {`Cancel SIP`}
                                                </span>
                                              </span>
                                            }
                                            style={{ fontSize: 22 }}
                                            name="cancel_sip"
                                            id={`cancel_sip`}
                                            value={`cancel_sip`}
                                            checked={
                                              this.state.modal_action_name === `cancel_sip`
                                            }
                                            onChange={(e) =>
                                              this.setState({
                                                modal_action_name: `cancel_sip`
                                              })
                                            }
                                            key={`action_cancel_sip`}
                                          />
                                          <br />
                                        </div>
                                      
                                    </div>
                                  }

                                </div>
                              }

                            </div>
                          </Col>
                        </Row>
                        <div
                          class="form-group"
                          style={{ marginBottom: 10, marginTop: 10 }}
                        ></div>
                      </Container>
                    </div>
                  )}
              </div>

            </Modal.Body>
            {!this.state.isloading &&
              <Modal.Footer>
                <Button
                  variant="primary"
                  onClick={() => {

                    if (this.state.modal_action_name === 'show_history')
                      this.showTransactionHistory(this.state.modal_action_order);
                    else if (this.state.modal_action_name === 'view_statement')
                      this.routeChange("xsip", this.state.modal_action_order, 'initiate');
                    else if (this.state.modal_action_name === 'view_info')
                      this.showChildInformation(
                        "xsip",
                        this.state.modal_action_order,
                        "view"
                      );
                    else if (this.state.modal_action_name === 'start_stp')
                      this.props.history.push({
                        pathname: "/stpSchemeList/",
                        state: {
                          request_mode: 'START STP', selected_order: this.state.modal_action_order
                        },
                      });
                    else if (this.state.modal_action_name === 'cancel_stp')
                      this.showStpInformation(
                        "xsip", this.state.modal_action_order, "show_stp_cancel_modal"
                      );
                    else if (this.state.modal_action_name === 'start_swp')
                      this.showSwpInformation(
                        "xsip", this.state.modal_action_order, "show_swp_modal"
                      );
                    else if (this.state.modal_action_name === 'cancel_swp')
                      this.showSwpInformation(
                        "xsip", this.state.modal_action_order, "show_swp_cancel_modal"
                      );
                    else if (this.state.modal_action_name === 'enable_pause_order')
                      this.onPauseOrder(
                        "xsip", this.state.modal_action_order, 'initiate'
                      );
                    else if (this.state.modal_action_name === 'disable_pause_order')
                      this.onPauseOrder(
                        "xsip", this.state.modal_action_order, 'initiate'
                      );
                      else if (this.state.modal_action_name === 'cancel_sip')
                        this.sendOTP('cancel_sip');
                  }
                  }
                  disabled={
                    this.state.modal_action_name.length < 7 ||
                    this.state.isloading
                  }
                >
                  Proceed
                </Button>
                {`      `}
                <Button
                  variant="secondary"
                  onClick={() =>
                    this.setState({
                      show_modal_action: false,
                      modal_action_name: ``
                    })
                  }
                >
                  Close
                </Button>
              </Modal.Footer>
            }
          </Modal>
        }

        {this.state
          .show_swp_cancel_modal &&
          <Modal
            show={
              this.state
                .show_swp_cancel_modal
            }
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            keyboard={false}
            fullscreen={true}
            backdrop="static"
          >
            <Modal.Header closeButton>
              <h4>Systematic Withdrawal Plan | Cancellation</h4>
            </Modal.Header>
            <Modal.Body>
              <div>

                <p>
                  {this.state.modal_orderinfo['mf_order_scheme_name']}
                </p>
                <hr />
                <p>
                  <span style={{ color: `#733dd1`, marginBottom: 0 }} >
                    Your 1st withdrawal placed on {new Date(this.state.modal_orderinfo['mf_order_swp_information']['start_date']).toLocaleString("en-IN", { year: 'numeric', month: 'short', day: 'numeric' })}
                  </span>
                </p>
                <table style={{ width: '100%' }}>
                  <tr>
                    <td style={{ height: 30, border: '1px solid #e2e0e4', backgroundColor: '#f7f5f5', }}>
                      <span>
                        SWP Number
                      </span>
                    </td>
                    <td style={{ height: 30, border: '1px solid #e2e0e4', textAlign: 'center' }}>
                      <span>
                        {this.state.modal_orderinfo['mf_order_swp_information']['registration_no']}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ height: 30, border: '1px solid #e2e0e4', backgroundColor: '#f7f5f5', }}>
                      <span>
                        Frequency
                      </span>
                    </td>
                    <td style={{ height: 30, border: '1px solid #e2e0e4', textAlign: 'center' }}>
                      <span>
                        {this.state.modal_orderinfo['mf_order_swp_information']['frequency']}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ height: 30, border: '1px solid #e2e0e4', backgroundColor: '#f7f5f5', }}>
                      <span>
                        No Of Withdrawals
                      </span>
                    </td>
                    <td style={{ height: 30, border: '1px solid #e2e0e4', textAlign: 'center' }}>
                      <span>
                        {this.state.modal_orderinfo['mf_order_swp_information']['no_of_installments']}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ height: 30, border: '1px solid #e2e0e4', backgroundColor: '#f7f5f5', }}>
                      <span>
                        SWP Amount
                      </span>
                    </td>
                    <td style={{ height: 30, border: '1px solid #e2e0e4', textAlign: 'center' }}>
                      <span>
                        {this.state.modal_orderinfo['mf_order_swp_information']['amount']}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ height: 30, border: '1px solid #e2e0e4', backgroundColor: '#f7f5f5', }}>
                      <span>
                        End Date
                      </span>
                    </td>
                    <td style={{ height: 30, border: '1px solid #e2e0e4', textAlign: 'center' }}>
                      <span>
                        {new Date(this.state.modal_orderinfo['mf_order_swp_information']['end_date']).toLocaleString("en-IN", { year: 'numeric', month: 'short', day: 'numeric' })}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ height: 30, border: '1px solid #e2e0e4', backgroundColor: '#f7f5f5', }}>
                      <span>
                        Recent Withdrawn Date
                      </span>
                    </td>
                    <td style={{ height: 30, border: '1px solid #e2e0e4', textAlign: 'center' }}>
                      <span>
                        {this.state.modal_orderinfo['mf_order_swp_information']['recent_withdrew_date'].length < 10 ? `-` :
                          new Date(this.state.modal_orderinfo['mf_order_swp_information']['recent_withdrew_date']).toLocaleString("en-IN", { year: 'numeric', month: 'short', day: 'numeric' })}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ height: 30, border: '1px solid #e2e0e4', backgroundColor: '#f7f5f5', }}>
                      <span>
                        Total Amount Withdrawn
                      </span>
                    </td>
                    <td style={{ height: 30, border: '1px solid #e2e0e4', textAlign: 'center' }}>
                      <span style={{ fontFamily: 'sans-serif' }}>&#8377;</span>
                      <span>
                        {this.state.modal_orderinfo['mf_order_swp_information']['total_withdrew_amount']}
                      </span>
                    </td>
                  </tr>
                </table>
                <div style={{ height: 15 }}></div>
                <table style={{ width: '100%' }}>
                  <tr>
                    <td style={{ height: 30, textAlign: 'left', }}>
                      <button
                        className="btn btn-warning btn-sm"
                        onClick={() => {
                          this.setState({
                            show_swp_cancel_modal: false,
                            modal_orderinfo: {},
                            swp_order: {
                              ...this.state.swp_order,
                              is_otp_enabled: false,
                              two_factor_authentication: {
                                mobile_otp: '',
                                email_otp: '',
                                twofactor_token_id: ''
                              }
                            }
                          })
                        }}
                      >
                        Close
                      </button>
                    </td>
                    <td style={{ height: 30, textAlign: 'right', }}>
                      <button
                        className="btn btn-success btn-sm"
                        disabled={
                          this.state.isloading ||
                          this.state.swp_order['is_otp_enabled']
                        }
                        onClick={() => {
                          this.sendOTP('swp_order', 'swp cancellation');
                        }}
                      >
                        Cancel SWP
                      </button>
                    </td>
                  </tr>
                </table>

              </div>
              {this.state.swp_order['is_otp_enabled'] &&
                <div>
                  <hr />
                  <div className="row">
                    <div class="col">
                      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Enter OTP received on your registered mobile</Form.Label>
                        <OTPInput
                          value={this.state.swp_order['two_factor_authentication']['mobile_otp']}
                          onChange={this.handle2FactorChange('mobile_otp', 'two_factor_authentication', 'swp_order')}
                          autoFocus
                          OTPLength={6}
                          otpType="number"
                          secure
                          isInputNum={true}
                        />
                      </Form.Group>
                    </div>
                  </div>
                  {
                    this.state.is_otp_email_required &&
                    // <br />
                    <Form.Group
                      className="mb-3"
                    >
                      <Form.Label>Enter OTP received on your registered email</Form.Label>
                      <OTPInput
                        value={this.state.swp_order['two_factor_authentication']['email_otp']}
                        onChange={this.handle2FactorChange('email_otp', 'two_factor_authentication', 'swp_order')}
                        OTPLength={6}
                        otpType="number"
                        secure
                        isInputNum={true}
                      />
                    </Form.Group>
                  }
                  {/* <br /> */}
                  <div className="row">
                    <ResendOTP maxTime={120} renderButton={renderButton} style={{ color: 'black', marginTop: 20 }} renderTime={renderTime}
                      onResendClick={(e) => { this.sendOTP('swp_order', 'swp cancellation') }}
                    />
                  </div>
                  <hr />
                  <div class="row">
                    <div class="col-6"></div>
                    <div class="col-6">
                      <table style={{ width: '100%' }}>
                        <tr>
                          <td style={{ height: 30, textAlign: 'right', }}>
                            <button
                              className="btn btn-success btn-sm"
                              onClick={() => {
                                this.onCancelSwpOrder("xsip", this.state.modal_orderinfo);
                              }}
                              disabled={
                                this.state.isloading ||
                                (this.state.is_otp_email_required && this.state.swp_order['two_factor_authentication']['email_otp'].length < 6) ||
                                this.state.swp_order['two_factor_authentication']['mobile_otp'].length < 6
                              }
                            >
                              Confirm
                            </button>
                          </td>
                        </tr>
                      </table>
                    </div>

                  </div>
                </div>
              }
            </Modal.Body>
            {this.state.isloading && (
              <Spinner
                animation="border"
                variant="dark"
                style={{
                  position: "fixed",
                  top: "50%",
                  left: "50%",
                  zIndex: 899,
                }}
              />
            )}
          </Modal>
        }


        {this.state.show_stp_cancel_modal &&
          <Modal
            show={
              this.state
                .show_stp_cancel_modal
            }
            onHide={() =>
              this.setState({
                show_stp_cancel_modal: false,
                modal_orderinfo: {},
                stp_order: {
                  ...this.state.stp_order,
                  is_otp_enabled: false,
                  two_factor_authentication: {
                    mobile_otp: '',
                    email_otp: '',
                    twofactor_token_id: ''
                  }
                }
              })
            }
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            keyboard={false}
            fullscreen={true}
            backdrop="static"
          >
            <Modal.Header closeButton>
              <h4>Systematic Transfer Plan - Cancellation </h4>

            </Modal.Header>
            <Modal.Body>
              <div>

                <p>
                  {this.state.modal_orderinfo['mf_order_scheme_name']}
                </p>
                <hr />
                <div className="row">
                  <div class="col-5">
                    <div class="form-group">
                      <p class="mr-sm-2 text-gray-dark" style={{ marginBottom: 0 }}> Frequency</p>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group">
                      {this.state.modal_orderinfo['mf_order_stp_information']['frequency']}
                    </div>
                  </div>
                </div>
                <br />
                <div className="row">
                  <div class="col-5">
                    <div class="form-group">
                      <p class="mr-sm-2 text-gray-dark" style={{ marginBottom: 0 }}>No Of Withdrawals</p>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group">
                      <span>
                        {this.state.modal_orderinfo['mf_order_stp_information']['no_of_installments']}
                      </span>
                    </div>
                  </div>
                </div>
                <br />
                <div className="row">
                  <div class="col-5">
                    <div class="form-group">
                      <p class="mr-sm-2 text-gray-dark" style={{ marginBottom: 0 }}>SWP Amount</p>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group">
                      <span>
                        {this.state.modal_orderinfo['mf_order_stp_information']['amount']}
                      </span>
                    </div>
                  </div>
                </div>
                <br />
                <div className="row">
                  <div className="col-5">
                    <div class="form-group">
                      End Date
                    </div>
                  </div>
                  <div className="col-6">
                    <span>
                      {new Date(this.state.modal_orderinfo['mf_order_stp_information']['end_date']).toLocaleString("en-IN", { year: 'numeric', month: 'short', day: 'numeric' })}
                    </span>
                  </div>
                </div>
                <br />
                <div className="row">
                  <div className="col-6">
                    <div class="form-group">
                      Recent Withdrew Date
                    </div>
                  </div>
                  <div className="col-6">
                    <span>
                      {this.state.modal_orderinfo['mf_order_stp_information']['recent_withdrew_date'].length < 10 ? `-` :
                        new Date(this.state.modal_orderinfo['mf_order_stp_information']['recent_withdrew_date']).toLocaleString("en-IN", { year: 'numeric', month: 'short', day: 'numeric' })}
                    </span>
                  </div>
                </div>
                <br />
                <div className="row">
                  <div className="col-6">
                    <div class="form-group">
                      Total Withdrew Amount
                    </div>
                  </div>
                  <div className="col-6">
                    <span>
                      {this.state.modal_orderinfo['mf_order_stp_information']['total_withdrew_amount']}
                    </span>
                  </div>
                </div>
                <br />
                <div className="row">
                  <p style={{ color: `#733dd1`, marginBottom: 0 }} >
                    Your 1st withdrawal will be placed on {new Date(this.state.modal_orderinfo['mf_order_stp_information']['start_date']).toLocaleString("en-IN", { year: 'numeric', month: 'short', day: 'numeric' })}
                  </p>
                  <br />
                </div>
                <br />
                <div class="row">
                  <div class="col-1"></div>
                  <div class="col-10">
                    <button
                      className="btn btn-success btn-sm"
                      style={{ fontSize: 12, marginBottom: 10, width: 280 }}
                      disabled={
                        this.state.isloading ||
                        this.state.swp_order['is_otp_enabled']
                      }
                      onClick={() => {
                        this.sendOTP('stp_order', 'stp cancellation');
                      }}
                    >
                      Cancel STP
                    </button>
                  </div>
                  <div class="col-1"></div>
                </div>
              </div>
              {this.state.stp_order['is_otp_enabled'] &&
                <div>
                  <hr />
                  <div className="row">
                    <div class="col">
                      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Enter OTP received on your registered mobile</Form.Label>
                        <OTPInput
                          value={this.state.stp_order['two_factor_authentication']['mobile_otp']}
                          onChange={this.handle2FactorChange('mobile_otp', 'two_factor_authentication', 'stp_order')}
                          autoFocus
                          OTPLength={6}
                          otpType="number"
                          secure
                          isInputNum={true}
                        />
                      </Form.Group>
                    </div>
                  </div>
                  <br />
                  <Form.Group
                    className="mb-3"
                  >
                    <Form.Label>Enter OTP received on your registered email</Form.Label>
                    <OTPInput
                      value={this.state.stp_order['two_factor_authentication']['email_otp']}
                      onChange={this.handle2FactorChange('email_otp', 'two_factor_authentication', 'stp_order')}
                      OTPLength={6}
                      otpType="number"
                      secure
                      isInputNum={true}
                    />
                  </Form.Group>
                  <br />
                  <div className="row">
                    <ResendOTP maxTime={120} renderButton={renderButton} style={{ color: 'black', marginTop: 20 }} renderTime={renderTime}
                      onResendClick={(e) => { this.sendOTP('stp_order', 'stp cancellation') }}
                    />
                  </div>
                  <hr />
                  <div class="row">
                    <div class="col">
                      <br />
                      <button
                        className="btn btn-success btn-sm"
                        onClick={() => {
                          this.onCancelStpOrder("xsip", this.state.modal_orderinfo);
                        }}
                        disabled={
                          this.state.isloading
                        }
                      >
                        Confirm
                      </button>
                    </div>

                  </div>
                </div>
              }
            </Modal.Body>
            {this.state.isloading && (
              <Spinner
                animation="border"
                variant="dark"
                style={{
                  position: "fixed",
                  top: "50%",
                  left: "50%",
                  zIndex: 899,
                }}
              />
            )}
          </Modal>
        }

      </>
    );
  }
}

// SipList.propTypes = {
//     validateOtp: PropTypes.func.isRequired,
//     isAuthenticated: PropTypes.bool
// };

/*
const mapStateToProps = state => ({
    loading: state.order.loading,
    order_result: state.order.order_result
});
*/

const mapStateToProps = ({ order }) => {
  const { loading, order_result } = order;
  return {
    loading,
    order_result,
  };
};

export default connect(mapStateToProps, {
  loadOrderList,
  loadSipStatus,
  cancelSipOrder,
  pauseSipOrder,
  createSwpOrder,
  twoFactorAuthentication,
  cancelSwpOrder,
  loadPaymentResponse
})(SipList);
